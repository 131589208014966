import EtfPositionsStore from "../models/etfPositions/stores/etfPositionsStore";
import EtfPosition from "../models/etfPositions/etfPosition";


class EtfPositionsViewModel {

    store: EtfPositionsStore;

    constructor(etfPositionsStore: EtfPositionsStore) {
        this.store = etfPositionsStore
    }

    getEtfPositions(
        representativeDate: string,
        identifierType: string,
        identifierValues: string[],
        maxDepth?: string
    ): Promise<EtfPosition[]> {
        return this.store.getEtfPositions(
            representativeDate, identifierType, identifierValues, maxDepth
        )
    }

    getOverlap(
        representativeDate: string,
        identifierType: string,
        identifierValues: string[]
    ): Promise<{[etfId: string]: {[etfId: string]: number}}>{
        return this.store.getOverlap(
            representativeDate, identifierType, identifierValues
        )
    }
}

export default EtfPositionsViewModel