const CreditRatingMapping: {[rating: string]: {[field: string]: string}} = {
    "AAA": {
      "TranslatedRating":"AAA",
      "HighLevelRating": "AAA",
      "RatingCategory":"Investment Grade",
      "Rank":"01",
      "HighLevelRank":"01"},
    "Aaa": {
      "TranslatedRating":"AAA",
      "HighLevelRating": "AAA",
      "RatingCategory":"Investment Grade",
      "Rank":"01",
      "HighLevelRank":"01"},
    "AAA(col)": {
      "TranslatedRating":"AAA",
      "HighLevelRating": "AAA",
      "RatingCategory":"Investment Grade",
      "Rank":"01",
      "HighLevelRank":"01"},
    "AA (high)": {
      "TranslatedRating":"AA+",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"02",
      "HighLevelRank":"02"},
    "AA+": {
      "TranslatedRating":"AA+",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"02",
      "HighLevelRank":"02"},
    "Aa1": {
      "TranslatedRating":"AA+",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"02",
      "HighLevelRank":"02"},
    "AA": {
      "TranslatedRating":"AA",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"03",
      "HighLevelRank":"02"},
    "Aa2": {
      "TranslatedRating":"AA",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"03",
      "HighLevelRank":"02"},
    "AA-": {
      "TranslatedRating":"AA-",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"04",
      "HighLevelRank":"02"},
    "AA (low)": {
      "TranslatedRating":"AA-",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"04",
      "HighLevelRank":"02"},
    "Aa3": {
      "TranslatedRating":"AA-",
      "HighLevelRating": "AA",
      "RatingCategory":"Investment Grade",
      "Rank":"04",
      "HighLevelRank":"02"},
    "A (high)": {
      "TranslatedRating":"A+",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"05",
      "HighLevelRank":"03"},
    "A+": {
      "TranslatedRating":"A+",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"05",
      "HighLevelRank":"03"},
    "A1": {
      "TranslatedRating":"A+",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"05",
      "HighLevelRank":"03"},
    "A": {
      "TranslatedRating":"A",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"06",
      "HighLevelRank":"03"},
    "A2": {
      "TranslatedRating":"A",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"06",
      "HighLevelRank":"03"},
    "A-": {
      "TranslatedRating":"A-",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"07",
      "HighLevelRank":"03"},
    "A (low)": {
      "TranslatedRating":"A-",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"07",
      "HighLevelRank":"03"},
    "A3": {
      "TranslatedRating":"A-",
      "HighLevelRating": "A",
      "RatingCategory":"Investment Grade",
      "Rank":"07",
      "HighLevelRank":"03"},
    "Baa1": {
      "TranslatedRating":"BBB+",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"08",
      "HighLevelRank":"04"},
    "BBB (high)": {
      "TranslatedRating":"BBB+",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"08",
      "HighLevelRank":"04"},
    "BBB+": {
      "TranslatedRating":"BBB+",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"08",
      "HighLevelRank":"04"},
    "Baa2": {
      "TranslatedRating":"BBB",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"09",
      "HighLevelRank":"04"},
    "BBB": {
      "TranslatedRating":"BBB",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"09",
      "HighLevelRank":"04"},
    "Baa3": {
      "TranslatedRating":"BBB-",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"10",
      "HighLevelRank":"04"},
    "BBB-": {
      "TranslatedRating":"BBB-",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"10",
      "HighLevelRank":"04"},
    "BBB (low)": {
      "TranslatedRating":"BBB-",
      "HighLevelRating": "BBB",
      "RatingCategory":"Investment Grade",
      "Rank":"10",
      "HighLevelRank":"04"},
    "Ba1": {
      "TranslatedRating":"BB+",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"11",
      "HighLevelRank":"05"},
    "BB+": {
      "TranslatedRating":"BB+",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"11",
      "HighLevelRank":"05"},
    "BB (high)": {
      "TranslatedRating":"BB+",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"11",
      "HighLevelRank":"05"},
    "Ba2": {
      "TranslatedRating":"BB",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"12",
      "HighLevelRank":"05"},
    "BB": {
      "TranslatedRating":"BB",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"12",
      "HighLevelRank":"05"},
    "Ba3": {
      "TranslatedRating":"BB-",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"13",
      "HighLevelRank":"05"},
    "BB-": {
      "TranslatedRating":"BB-",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"13",
      "HighLevelRank":"05"},
    "BB (low)": {
      "TranslatedRating":"BB-",
      "HighLevelRating": "BB",
      "RatingCategory":"High Yield",
      "Rank":"13",
      "HighLevelRank":"05"},
    "B (high)": {
      "TranslatedRating":"B+",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"14",
      "HighLevelRank":"06"},
    "B+": {
      "TranslatedRating":"B+",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"14",
      "HighLevelRank":"06"},
    "B1": {
      "TranslatedRating":"B+",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"14",
      "HighLevelRank":"06"},
    "B": {
      "TranslatedRating":"B",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"15",
      "HighLevelRank":"06"},
    "B2": {
      "TranslatedRating":"B",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"15",
      "HighLevelRank":"06"},
    "B-": {
      "TranslatedRating":"B-",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"16",
      "HighLevelRank":"06"},
    "B (low)": {
      "TranslatedRating":"B-",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"16",
      "HighLevelRank":"06"},
    "B3": {
      "TranslatedRating":"B-",
      "HighLevelRating": "B",
      "RatingCategory":"High Yield",
      "Rank":"16",
      "HighLevelRank":"06"},
    "Caa1": {
      "TranslatedRating":"CCC+",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"17",
      "HighLevelRank":"07"},
    "CCC+": {
      "TranslatedRating":"CCC+",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"17",
      "HighLevelRank":"07"},
    "Caa2": {
      "TranslatedRating":"CCC",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"18",
      "HighLevelRank":"07"},
    "CCC": {
      "TranslatedRating":"CCC",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"18",
      "HighLevelRank":"07"},
    "Caa3": {
      "TranslatedRating":"CCC-",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"19",
      "HighLevelRank":"07"},
    "CCC-": {
      "TranslatedRating":"CCC-",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"19",
      "HighLevelRank":"07"},
    "Ca": {
      "TranslatedRating":"CC",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"20",
      "HighLevelRank":"07"},
    "CC": {
      "TranslatedRating":"CC",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"20",
      "HighLevelRank":"07"},
    "C": {
      "TranslatedRating":"C",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"21",
      "HighLevelRank":"07"},
    "D": {
      "TranslatedRating":"D",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"22",
      "HighLevelRank":"07"},
    "/": {
      "TranslatedRating":"D",
      "HighLevelRating": "CCC & below",
      "RatingCategory":"High Yield",
      "Rank":"22",
      "HighLevelRank":"07"},
    "np.NaN": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "None": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "1": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "2(80%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "3(50%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "3(55%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "3(65%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "4(30%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "4(35%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "4(40%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "4(45%)": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "Discontinued": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "nan": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "NaN": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "NR": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "<N/A>": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "<NA>": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "NULL": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "RR1": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "RR2": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "RR3": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "RR4": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "RR5": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "Total Weighted": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "Unable to collect data for the field 'TR.GR.Rating' and some specific identifier(s).": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "WD": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"},
    "WR": {
      "TranslatedRating":"Not Rated",
      "HighLevelRating": "Not Rated",
      "RatingCategory":"Not Rated",
      "Rank":"23",
      "HighLevelRank":"08"}
};

export default CreditRatingMapping;