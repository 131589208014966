import ClientPosition from "./clientPosition";

class ClientPositionWithLookThrough extends ClientPosition {

    etfIdentifiers: { [identifierType: string] : string; };

    underlyingInstrumentIdentifiers: { [identifierType: string] : string; };

    representativeDate?: string;

    providedEtfId?: string;

    etfId?: string;

    etfIdentifierType?: string;

    etfIdentifierValue?: string;

    etfName?: string;

    etfDescription?: string;

    etfCurrency?: string;

    etfType?: string;

    holdingsDate?: string;

    underlyingInstrumentId?: string;

    instrumentIdentifierType?: string;

    instrumentIdentifierValue?: string;

    underlyingInstrumentName?: string;

    underlyingInstrumentDescription?: string;

    underlyingInstrumentCurrency?: string;

    underlyingInstrumentType?: string;

    underlyingInstrumentStatus?: string;

    weight?: number;

    lineage?: string;

    constructor(
        id: number,
        rawId: number,
        custodianId: number,
        fileProcessId: number,
        assetClass: string,
        accountNumber: string,
        positionDate: string,
        currency: string,
        exchangeRate: number,
        quantity: number,
        avgorUnitCost: number,
        marketPrice: number,
        marketValue: number,
        securityName: string,
        securityIsin: string,
        securityRic: string,
        marketValueReportingCurrency: number,
        accruedInterest: number,
        etfIdentifiers: { [identifierType: string] : string; },
        underlyingInstrumentIdentifiers: { [identifierType: string] : string; },
        assetSubClass?: string,
        securityCusip?: string,
        securitySymbol?: string,
        exchangeCode?: string,
        representativeDate?: string,
        providedEtfId?: string,
        etfId?: string,
        etfIdentifierType?: string,
        etfIdentifierValue?: string,
        etfName?: string,
        etfDescription?: string,
        etfCurrency?: string,
        etfType?: string,
        holdingsDate?: string,
        underlyingInstrumentId?: string,
        instrumentIdentifierType?: string,
        instrumentIdentifierValue?: string,
        underlyingInstrumentName?: string,
        underlyingInstrumentDescription?: string,
        underlyingInstrumentCurrency?: string,
        underlyingInstrumentType?: string,
        underlyingInstrumentStatus?: string,
        weight?: number,
        lineage?: string
        ){
            super(
                id,
                rawId,
                custodianId,
                fileProcessId,
                assetClass,
                accountNumber,
                positionDate,
                currency,
                exchangeRate,
                quantity,
                avgorUnitCost,
                marketPrice,
                marketValue,
                securityName,
                securityIsin,
                securityRic,
                marketValueReportingCurrency,
                accruedInterest,
                assetSubClass,
                securityCusip,
                securitySymbol,
                exchangeCode
            );

            this.etfId = etfId;
            this.etfIdentifierType = etfIdentifierType;
            this.etfIdentifierValue = etfIdentifierValue;
            this.providedEtfId = providedEtfId;
            this.etfName = etfName;
            this.etfDescription = etfDescription;
            this.etfCurrency = etfCurrency;
            this.etfType = etfType;
            this.representativeDate = representativeDate;
            this.holdingsDate = holdingsDate;
            this.underlyingInstrumentId = underlyingInstrumentId;
            this.instrumentIdentifierType = instrumentIdentifierType;
            this.instrumentIdentifierValue = instrumentIdentifierValue;
            this.underlyingInstrumentName = underlyingInstrumentName;
            this.underlyingInstrumentDescription = underlyingInstrumentDescription;
            this.underlyingInstrumentCurrency = underlyingInstrumentCurrency;
            this.underlyingInstrumentType = underlyingInstrumentType;
            this.underlyingInstrumentStatus = underlyingInstrumentStatus;
            this.weight = Number(weight);
            this.quantity = Number(quantity);
            this.lineage = lineage;
            this.etfIdentifiers = etfIdentifiers;
            this.underlyingInstrumentIdentifiers = underlyingInstrumentIdentifiers;


    }
}

export default ClientPositionWithLookThrough;