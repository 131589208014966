class SummaryStatistic{

    group: string;

    count: number;

    constructor(group: string, count: number){
        this.group = group;
        this.count = count;
    }
}

export default SummaryStatistic;