import {Data, Layout, PlotType} from "plotly.js";
import BranchValues from "./types";
import ChartData from "../interfaces/chartData";

export default function ombaHeatmap(
    data: Data[],
    layout: Partial<Layout>,
    aspectRatio: number = 1
): ChartData{

    const height = 1000;

    return {
        data: data,
        layout: {
            ...layout
        },
        image: {format: "png", width: height * aspectRatio, height: height, scale: 2}
    };
}