import {useEffect, useState} from 'react';
import Dashboard from "../views/dashboard";
import Column from "../interfaces/column";
import SmallBoxInfo from "../interfaces/props/views/smallBoxInfo";
import Variant from "../interfaces/enums/variant";
import BasicFormInfo from "../interfaces/props/views/basicFormInfo";
import FormField from "../interfaces/formField";
import FormModalInfo from "../interfaces/props/views/formModalInfo";
import * as React from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ExportToCsv } from 'export-to-csv';
import ClientPosition from "../models/clientPositions/clientPosition";
import ClientPositionsDashboardControllerInfo
    from "../interfaces/props/controllers/clientPositionsDashboardControllerInfo";
import ClientPositionWithLookThrough from "../models/clientPositions/clientPositionWithLookThrough";
import DataTableInfo from "../interfaces/props/views/dataTableInfo";

const MySwal = withReactContent(Swal);

function ClientPositionsDashboardController(props: ClientPositionsDashboardControllerInfo){

    const [clientPositions, setClientPositions] = useState<ClientPosition[]>([]);
    const [lookThroughView, setLookThroughView] = useState<boolean>(false);
    const [clientPositionsWithLookThrough, setClientPositionsWithLookThrough] = useState<ClientPositionWithLookThrough[]>([]);

    // The field to use to get Client Positions
    const defaultClientPositionsFormFields : {[id: string]: FormField} = {
        // Text input field for the representative date
        "representativeDate": new FormField(
            "Representative Date",
            "representativeDate",
            "representativeDate",
            "string",
            "Default",
            false,
            "",
            "2020-01-01"
        ),
        "lookThrough": new FormField(
            "Lookthrough?",
            "lookThrough",
            "lookThrough",
            "checkbox",
            "Default",
            false,
            "checked=off",
        ),
        "aggregate": new FormField(
            "Aggregate?",
            "aggregate",
            "aggregate",
            "checkbox",
            "Default",
            false,
            "checked=off",
        ),
        "clientAccountNumber0": new FormField(
            "Client Account Number",
            "clientAccountNumber0",
            "clientAccountNumber0",
            "string",
            "Accounts",
            false,
            "",
            "OFM_USD"
        )
    };

    const [getClientPositionFormFields, setClientPositionFormFields] = useState<{[id: string]: FormField}>(defaultClientPositionsFormFields);

    // Used to construct the account client numbers
    const constructAccountClientNumbers = (clientNumbers: {[id: string]: FormField}, prefix: string): string[] => {

        let clientAccountNumbers: string[] = [];

        for (const [id, formField] of Object.entries(clientNumbers)) {
            if (id.includes(prefix)){
                clientAccountNumbers.push(formField.value)
            }
        }

        return clientAccountNumbers;
    };


    // These are the columns known in advance to use in the datatable to show client positions
    const columns:Column[]  = [
            {
                label: 'Id',
                field: 'Id',
                width: 10
            },
            {
                label: 'Raw Id',
                field: 'RawId',
                width: 10
            },
            {
                label: 'Custodian Id',
                field: 'CustodianId',
                width: 10
            },
            {
                label: 'File Process Id',
                field: 'FileProcessId',
                width: 10
            },
            {
                label: 'Asset Class',
                field: 'AssetClass',
                width: 10
            },
            {
                label: 'Asset Sub Class',
                field: 'AssetSubClass',
                width: 10
            },
            {
                label: 'Account Number',
                field: 'AccountNumber',
                width: 10
            },
            {
                label: 'Position Date',
                field: 'PositionDate',
                width: 10
            },
            {
                label: 'Currency',
                field: 'Currency',
                width: 10
            },
            {
                label: 'Exchange Rate',
                field: 'ExchangeRate',
                width: 10
            },
            {
                label: 'Quantity',
                field: 'Quantity',
                width: 10
            },
            {
                label: 'Avgor Unit Cost',
                field: 'AvgorUnitCost',
                width: 10
            },
            {
                label: 'Market Price',
                field: 'MarketPrice',
                width: 10
            },
            {
                label: 'Market Value',
                field: 'MarketValue',
                width: 10
            },
            {
                label: 'Security Name',
                field: 'SecurityName',
                width: 10
            },
            {
                label: 'Security ISIN',
                field: 'SecurityISIN',
                width: 10
            },
            {
                label: 'Security CUSIP',
                field: 'SecurityCUSIP',
                width: 10
            },
            {
                label: 'Security RIC',
                field: 'SecurityRIC',
                width: 10
            },
            {
                label: 'Security Symbol',
                field: 'SecuritySymbol',
                width: 10
            },
            {
                label: 'Exchange Code',
                field: 'ExchangeCode',
                width: 10
            },
            {
                label: 'Market Value Reporting Currency',
                field: 'MarketValueReportingCurrency',
                width: 10
            },
            {
                label: 'Accrued Interest',
                field: 'AccruedInterest',
                width: 10
            },

        ];

    // These are the columns known in advance to use in the datatable to show client positions with look-through
    const lookThroughColoumns:Column[]  = [
        {
            label: 'Etf Id',
            field: 'ETFId',
            width: 10
        },
        {
            label: 'Provided Etf Id',
            field: 'ProvidedETFId',
            width: 10
        },
        {
            label: 'ETF Identifier Type',
            field: 'ETFIdentifierType',
            width: 10
        },
        {
            label: 'ETF Identifier Value',
            field: 'ETFIdentifierValue',
            width: 10
        },
        {
            label: 'ETF Name',
            field: 'ETFName',
            width: 10
        },
        {
            label: 'ETF Description',
            field: 'ETFDescription',
            width: 10
        },
        {
            label: 'ETF Currency',
            field: 'ETFCurrency',
            width: 10
        },
        {
            label: 'ETF Type',
            field: 'ETFType',
            width: 10
        },
        {
            label: 'Representative Date',
            field: 'RepresentativeDate',
            width: 10
        },
        {
            label: 'Holdings Date',
            field: 'HoldingsDate',
            width: 10
        },
        {
            label: 'Underlying Instrument Id',
            field: 'UnderlyingInstrumentId',
            width: 10
        },
        {
            label: 'Underlying Instrument Identifier Type',
            field: 'UnderlyingInstrumentIdentifierType',
            width: 10
        },
        {
            label: 'Underlying Instrument Identifier Value',
            field: 'UnderlyingInstrumentIdentifierValue',
            width: 10
        },
        {
            label: 'Underlying Instrument Name',
            field: 'UnderlyingInstrumentName',
            width: 10
        },
        {
            label: 'Underlying Instrument Description',
            field: 'UnderlyingInstrumentDescription',
            width: 10
        },
        {
            label: 'Underlying Instrument Currency',
            field: 'UnderlyingInstrumentCurrency',
            width: 10
        },
        {
            label: 'Underlying Instrument Type',
            field: 'UnderlyingInstrumentType',
            width: 10
        },
        {
            label: 'Underlying Instrument Status',
            field: 'UnderlyingInstrumentStatus',
            width: 10
        },
        {
            label: 'Weight (%)',
            field: 'Weight',
            width: 10
        },
        {
            label: 'Quantity',
            field: 'Quantity',
            width: 10
        },
        {
            label: 'Lineage',
            field: 'Lineage',
            width: 20
        }

    ];

    const displayBoxes:SmallBoxInfo[] = [
        {
            value: constructAccountClientNumbers(getClientPositionFormFields, "clientAccountNumber").length.toString(),
            label: "Number of Accounts",
            type: constructAccountClientNumbers(getClientPositionFormFields, "clientAccountNumber").length > 0 ? Variant.Success : Variant.Danger
        },
        {
            value: lookThroughView ? clientPositionsWithLookThrough.length.toString() : clientPositions.length.toString(),
            label: "Total Number of Positions",
            type: (lookThroughView ? clientPositionsWithLookThrough.length : clientPositions.length) > 0 ? Variant.Info : Variant.Danger
        }
    ];

    // This is invoked when a user wants to add another identifier when creating an instrument
    const addAccountNumberToForm = (e:Event) => {

        const numberFields = Object.keys(getClientPositionFormFields).filter(key => key.includes("clientAccountNumber")).length.toString();
        const etfFieldName = "clientAccountNumber" + numberFields;

        // Add new selectable drop down for the identifier type and a text input for the identifier value
        setClientPositionFormFields({
                ...getClientPositionFormFields,
                [etfFieldName]:  new FormField(
                "Client Account Number",
                    etfFieldName,
                    etfFieldName,
                "string",
                "Accounts",
                false,
                "",
                "OFM_USD"
            )
        });
    };

    // Ensures that when the form is updated the new state of the form is saved
    const updateFormData = (e:React.FormEvent<HTMLInputElement>) => {

        let formFieldId = e.currentTarget.id.split("-")[0];
        let formField = getClientPositionFormFields[formFieldId];

        if (e.currentTarget.id.includes("-select")) {
            formField.selectValue = e.currentTarget.value;
        }

        else if (e.currentTarget.type == "checkbox"){
            formField.checked = !formField.checked
        }

        else {
            formField.value = e.currentTarget.value;
        }

        setClientPositionFormFields(prevState => (
            {
                ...prevState,
                [formFieldId]: formField
            }));
    };

    // Handles getting ETF data
    const getClientPositionData = (e:React.FormEvent<HTMLInputElement>) => {
        e.preventDefault(); // This ensures that the form submit does not cause a HTTP request in the browser
        MySwal.fire({
            title: 'Fetching Client Positions... please wait',
            icon: "info",
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            didOpen: () => {
                Swal.showLoading();

                let aggregateMethod = getClientPositionFormFields["aggregate"].checked ? "aggregate" : "individual";
                let lookThrough = getClientPositionFormFields["lookThrough"].checked;

                if ((!lookThrough) && (aggregateMethod === "aggregate")){
                    MySwal.fire({
                        title: 'Aggregation not supported without look-through. Please un-check the aggregate option OR check the look-through option.',
                        icon: "error",
                        position: 'top-end',
                        toast: true
                    });
                    return;
                }


                lookThrough

                ?

                    props.clientPositionViewModel.getClientPositionsWithLookThrough(
                        getClientPositionFormFields["representativeDate"].value,
                        constructAccountClientNumbers(getClientPositionFormFields, "clientAccountNumber"),
                        getClientPositionFormFields["lookThrough"].checked ? "5" : "0",
                        aggregateMethod
                    ).then((clientPositions) => {
                        setClientPositionsWithLookThrough(clientPositions);
                        setClientPositions([]);
                        setLookThroughView(true);
                    }).then(() =>
                        MySwal.fire({
                            title: 'Client Positions Retrieved!',
                            icon: "success",
                            position: 'top-end',
                            toast: true
                        })
                    ).catch((error) => MySwal.fire({
                        title: 'Failure!',
                        text: error,
                        icon: "error",
                        position: 'top-end',
                        toast: true
                    }))

                :


                    props.clientPositionViewModel.getClientPositions(
                        getClientPositionFormFields["representativeDate"].value,
                        constructAccountClientNumbers(getClientPositionFormFields, "clientAccountNumber")
                    ).then((clientPositions) => {
                        setClientPositions(clientPositions);
                        setClientPositionsWithLookThrough([]);
                        setLookThroughView(false);
                    }).then(() =>
                        MySwal.fire({
                            title: 'Client Positions Retrieved!',
                            icon: "success",
                            position: 'top-end',
                            toast: true
                        })
                    ).catch((error) => MySwal.fire({
                        title: 'Failure!',
                        text: error,
                        icon: "error",
                        position: 'top-end',
                        toast: true
                    }));
            }
        });

    };

    // Handles closing the form by resetting the values
    const closeForm = (e:React.FormEvent<HTMLInputElement>) => {
        setClientPositionFormFields(defaultClientPositionsFormFields);
    };

    const getEtfPositionsFormInfo: BasicFormInfo = {
        "formSubmitAction": {
            "action": getClientPositionData
        },
        "formOnChangeAction": {
            "action": updateFormData
        },
        "formSubmitLabel": "Get Positions",
        "formAddLabel": "Add Client Account",
        "formFields": Object.values(getClientPositionFormFields),
        "formOnAddAction": {
            "action": addAccountNumberToForm
        },
        "formCloseAction": {
            "action": closeForm
        }
    };

    const accountSelectorFormModalInfo: FormModalInfo = {
        "modalId": "AccountSelector",
        "modalTitle": "Select Accounts",
        "formInfo": getEtfPositionsFormInfo
    };

    // These are arbitrary columns to use depending on what identifiers exist, for example if ISIN, CUSIP and RIC
    // are the available identifiers there will be an ISIN, CUSIP and RIC column
    const idColumns = (clientPositionWithLookThrough: ClientPositionWithLookThrough[]) => {
        if (clientPositionWithLookThrough.length == 0){
            return []
        }

        var etfIds: Column[] = [];
        var underlyingInstrumentIds: Column[] = [];

        Object.entries(clientPositionWithLookThrough[0].etfIdentifiers).forEach(
            ([key, value]) => etfIds.push(
                {
                    label: "ETFInstrumentIdentifier." + key,
                    field: "ETFInstrumentIdentifier." + key,
                    width: 10
                }
            )
        );

        Object.entries(clientPositionWithLookThrough[0].underlyingInstrumentIdentifiers).forEach(
            ([key, value]) => underlyingInstrumentIds.push(
                {
                    label: "UnderlyingInstrumentIdentifier." + key,
                    field: "UnderlyingInstrumentIdentifier." + key,
                    width: 10
                }
            )
        );

        return etfIds.concat(underlyingInstrumentIds);
    };

    // These are arbitrary columns to use depending on what identifiers exist, for example if ISIN, CUSIP and RIC
    // are the available identifiers there will be an ISIN, CUSIP and RIC column
    const idValues = (clientPositionWithLookThrough: ClientPositionWithLookThrough) => {

        var identifierValues: { [identifierType: string] : string; } = {};

        Object.entries(clientPositionWithLookThrough.etfIdentifiers).forEach(
            ([key, value]) => identifierValues["ETFInstrumentIdentifier." + key] = value
        );

        Object.entries(clientPositionWithLookThrough.underlyingInstrumentIdentifiers).forEach(
            ([key, value]) => {
                if ((value == null) && (clientPositionWithLookThrough.instrumentIdentifierValue == null)){
                    switch (key){
                        case "KeyRIC": {
                            identifierValues["UnderlyingInstrumentIdentifier." + key] = clientPositionWithLookThrough.securityRic;
                            return;
                        }
                        case "ISIN": {
                            identifierValues["UnderlyingInstrumentIdentifier." + key] = clientPositionWithLookThrough.securityIsin;
                            return;
                        }
                        default:
                            break;
                    }
                }

                identifierValues["UnderlyingInstrumentIdentifier." + key] = value
            }
        );

        return identifierValues;
    };

    // Creates the datatable to display
    const datatable ={
        columns: lookThroughView ? columns.concat(lookThroughColoumns).concat(idColumns(clientPositionsWithLookThrough)) : columns,
        rows: lookThroughView

            ?

            clientPositionsWithLookThrough.map(clientPosition => {
                let baseProps = {
                    "Id": clientPosition.id,
                    "RawId": clientPosition.rawId,
                    "CustodianId": clientPosition.custodianId,
                    "FileProcessId": clientPosition.fileProcessId,
                    "AssetClass": clientPosition.assetClass,
                    "AssetSubClass": clientPosition.assetSubClass,
                    "AccountNumber": clientPosition.accountNumber,
                    "PositionDate": clientPosition.positionDate,
                    "Currency": clientPosition.currency,
                    "ExchangeRate": clientPosition.exchangeRate,
                    "Quantity": clientPosition.quantity,
                    "AvgorUnitCost": clientPosition.avgorUnitCost,
                    "MarketPrice": clientPosition.marketPrice,
                    "MarketValue": clientPosition.marketValue,
                    "SecurityName": clientPosition.securityName,
                    "SecurityISIN": clientPosition.securityIsin,
                    "SecurityCUSIP": clientPosition.securityCusip,
                    "SecurityRIC": clientPosition.securityRic,
                    "SecuritySymbol": clientPosition.securitySymbol,
                    "ExchangeCode": clientPosition.exchangeCode,
                    "MarketValueReportingCurrency": clientPosition.marketValueReportingCurrency,
                    "AccruedInterest": clientPosition.accruedInterest,
                    "Lineage": clientPosition.lineage,
                    "Weight": clientPosition.weight,
                    "ETFId": clientPosition.etfId,
                    "ProvidedETFId": clientPosition.providedEtfId,
                    "ETFIdentifierType": clientPosition.etfIdentifierType,
                    "ETFIdentifierValue": clientPosition.etfIdentifierValue,
                    "ETFName": clientPosition.etfName,
                    "ETFDescription": clientPosition.etfDescription,
                    "ETFCurrency": clientPosition.etfCurrency,
                    "ETFType": clientPosition.etfType,
                    "RepresentativeDate": clientPosition.representativeDate,
                    "HoldingsDate": clientPosition.holdingsDate,
                    "UnderlyingInstrumentId": clientPosition.underlyingInstrumentId,
                    "UnderlyingInstrumentIdentifierType": clientPosition.instrumentIdentifierType,
                    "UnderlyingInstrumentIdentifierValue": clientPosition.instrumentIdentifierValue,
                    "UnderlyingInstrumentName": clientPosition.underlyingInstrumentName,
                    "UnderlyingInstrumentDescription": clientPosition.underlyingInstrumentDescription,
                    "UnderlyingInstrumentCurrency": clientPosition.underlyingInstrumentCurrency,
                    "UnderlyingInstrumentType": clientPosition.underlyingInstrumentType,
                    "UnderlyingInstrumentStatus": clientPosition.underlyingInstrumentStatus,
                };

                let identifiers = idValues(clientPosition);

                return {
                    ...baseProps,
                    ...identifiers
                }

            })

            :

            clientPositions.map(clientPosition => {
                return {
                    "Id": clientPosition.id,
                    "RawId": clientPosition.rawId,
                    "CustodianId": clientPosition.custodianId,
                    "FileProcessId": clientPosition.fileProcessId,
                    "AssetClass": clientPosition.assetClass,
                    "AssetSubClass": clientPosition.assetSubClass,
                    "AccountNumber": clientPosition.accountNumber,
                    "PositionDate": clientPosition.positionDate,
                    "Currency": clientPosition.currency,
                    "ExchangeRate": clientPosition.exchangeRate,
                    "Quantity": clientPosition.quantity,
                    "AvgorUnitCost": clientPosition.avgorUnitCost,
                    "MarketPrice": clientPosition.marketPrice,
                    "MarketValue": clientPosition.marketValue,
                    "SecurityName": clientPosition.securityName,
                    "SecurityISIN": clientPosition.securityIsin,
                    "SecurityCUSIP": clientPosition.securityCusip,
                    "SecurityRIC": clientPosition.securityRic,
                    "SecuritySymbol": clientPosition.securitySymbol,
                    "ExchangeCode": clientPosition.exchangeCode,
                    "MarketValueReportingCurrency": clientPosition.marketValueReportingCurrency,
                    "AccruedInterest": clientPosition.accruedInterest
                };
            })
        };

    const Export = () => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            title: 'ClientPositions',
            useKeysAsHeaders: true,
        };

        const csvExporter = new ExportToCsv(options);

        if (datatable.rows.length == 0){
            MySwal.fire({
                title: 'No data to export!',
                position: 'top-end',
                icon: 'error',
                toast: true
            });
            return
        }

        csvExporter.generateCsv(datatable.rows);
    };

    const exportFunction = {"action": Export};

    const dataTableInfo: DataTableInfo = {
        "data": datatable,
        "entries": 1000
    }

    return(
        <Dashboard
            clickLabel={"Select Accounts"}
            newEntityFormModalInfo={accountSelectorFormModalInfo}
            editEntityFormModalInfo={accountSelectorFormModalInfo}
            datatableInfo={dataTableInfo}
            displayBoxes={displayBoxes}
            exportFunction={exportFunction}/>
    )

}

export default ClientPositionsDashboardController;