import {Text, View} from "@react-pdf/renderer";
import React from "react";
import TableHeaderInfo from "../../interfaces/props/reports/tableHeaderInfo";
import columnWidthCalculator from "./columnWidthCalculator";
import Column from "../../interfaces/column";


function TableHeader(props: TableHeaderInfo){

    const {
        columns,
        rowStyles,
        cellStyles,
        headerRowStyles,
        headerCellStyles
    } = props;

    const getHeaderData = (columns: Column[]) => {
        
        return columns.map(column => {
            return <Text key={column.field} style={[cellStyles, headerCellStyles, {width: columnWidthCalculator(column, props.columns)}]}>
                    {column.label}
                </Text>
        })
    };

    return <View wrap={false} style={[rowStyles, headerRowStyles]}>{getHeaderData(columns)}</View>
}

export default TableHeader;