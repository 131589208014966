import { Route } from 'react-router';
import {MsalAuthenticationTemplate} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {loginRequestScopes} from "../authConfig";
import * as React from "react";


/*
Credit to Marc 2021 update from @Robin's answer here:
https://stackoverflow.com/questions/47747754/how-to-rewrite-the-protected-private-route-using-typescript-and-react-router-4
 */
export default function ProtectedRoute({...routeProps}) {

    let {children, ...rest} = routeProps;
    return <Route {...rest} >
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequestScopes}
        >
            {children}
        </MsalAuthenticationTemplate>
    </Route>;
};