import InstrumentStore from "../models/instruments/stores/instrumentStore";
import Instrument from "../models/instruments/instrument";
import Identifier from "../models/instruments/identifier";
import Attribute from "../models/instruments/attribute";
import SummaryStatistic from "../models/instruments/summaryStatistic";


class InstrumentViewModel {

    store: InstrumentStore;

    constructor(instrumentStore: InstrumentStore) {
        this.store = instrumentStore
    }

    getSummary(summaryField: string): Promise<SummaryStatistic[]>{
        return this.store.getSummary(summaryField)
    }

    getAttributes(source: string): Promise<Attribute[]>{
        return this.store.getAttributes(source)
    }

    listInstruments(limit?: number, offset?: number,  attributes?: string[], search?: string): Promise<Instrument[]> {
        return this.store.listInstruments(limit, offset, attributes, search)
    }

    getInstrument(instrumentId: string, attributes: string[]): Promise<Instrument> {
        return this.store.getInstrument(instrumentId, attributes)
    }

    getInstruments(instrumentIds: string[], attributes: string[], asOfDate?: string, currency?: string): Promise<Instrument[]> {
        return this.store.getInstruments(instrumentIds, attributes, asOfDate, currency)
    }

    upsertInstruments(instruments: Instrument[]): Promise<Instrument[]> {
        return this.store.upsertInstruments(instruments)
    }

    deleteInstrument(instrumentId: string): Promise<string> {
        return this.store.deleteInstrument(instrumentId)
    }

    listIdentifiers(): Promise<Identifier[]> {
        return this.store.listIdentifiers()
    }
}

export default InstrumentViewModel