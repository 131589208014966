export enum RefinitivInstrumentAttributes {

    BenchmarkName = 'Refinitiv/BenchmarkName',
    BenchmarkRIC = 'Refinitiv/BenchmarkRIC',
    DividendCurrency = 'Refinitiv/DividendCurrency',
    ExchangeCode = 'Refinitiv/ExchangeCode',
    FundCustodian = 'Refinitiv/FundCustodian',
    FundLegalStructure = 'Refinitiv/FundLegalStructure',
    FundObjective = 'Refinitiv/FundObjective',
    IncomeDistributionIndicator = 'Refinitiv/IncomeDistributionIndicator',
    InstrumentAssetClass = 'Refinitiv/InstrumentAssetClass',
    InstrumentDomicile = 'Refinitiv/InstrumentDomicile',
    InstrumentFocusCountry = 'Refinitiv/InstrumentFocus_Country',
    InstrumentLaunchDate = 'Refinitiv/InstrumentLaunchDate',
    NumberOfDividendPaymentPerYear = 'Refinitiv/NumberOfDividendPaymentPerYear',
    OperatingMIC = 'Refinitiv/OperatingMIC',
    TotalExpenseRatio = 'Refinitiv/TotalExpenseRatio',
    TotalExpenseRatioDate = 'Refinitiv/TotalExpenseRatio_Date',
}

export enum ManualInstrumentAttributes {

    BenchmarkFamily = 'Manual/BenchmarkFamily',
    CurrencyHedged = 'Manual/CurrencyHedged',
    CustomInstrument1Ccy = 'Manual/CustomInstrument1_Ccy',
    CustomInstrument1Closeness = 'Manual/CustomInstrument1_Closeness',
    CustomInstrument1Name = 'Manual/CustomInstrument1_Name',
    CustomInstrument2Ccy = 'Manual/CustomInstrument2_Ccy',
    CustomInstrument2Closeness = 'Manual/CustomInstrument2_Closeness',
    CustomInstrument2Name = 'Manual/CustomInstrument2_Name',
    CustomInstrument3Ccy = 'Manual/CustomInstrument3_Ccy',
    CustomInstrument3Closeness = 'Manual/CustomInstrument3_Closeness',
    CustomInstrument3Name = 'Manual/CustomInstrument3_Name',
    ESGMethod = 'Manual/ESG_Method',
    ESGSFRDClassification = 'Manual/ESG_SFRDClassification',
    ESGTarget = 'Manual/ESG_Target',
    ESGFlag = 'Manual/ESGFlag',
    FileProcessId = 'Manual/FileProcessId',
    FundLegalType = 'Manual/FundLegalType',
    InstrumentFocusBondType = 'Manual/InstrumentFocus_BondType',
    InstrumentFocusCreditRating = 'Manual/InstrumentFocus_CreditRating',
    InstrumentFocusCurrency = 'Manual/InstrumentFocus_Currency',
    InstrumentFocusMaturity = 'Manual/InstrumentFocus_Maturity',
    InstrumentFocusRegion = 'Manual/InstrumentFocus_Region',
    InstrumentFocusSector = 'Manual/InstrumentFocus_Sector',
    InstrumentFocusStyleFactor = 'Manual/InstrumentFocus_StyleFactor',
    InstrumentFocusSubRegion = 'Manual/InstrumentFocus_SubRegion',
    InstrumentFocusThematicFlag = 'Manual/InstrumentFocus_Thematic_Flag',
    InstrumentFocusThematicMegaTrend = 'Manual/InstrumentFocus_Thematic_MegaTrend',
    InstrumentFocusThematicSubTheme = 'Manual/InstrumentFocus_Thematic_SubTheme',
    InstrumentFocusThematicTheme = 'Manual/InstrumentFocus_Thematic_Theme',
    InstrumentHoldingsURL = 'Manual/InstrumentHoldingsURL',
    InstrumentIssuerName = 'Manual/InstrumentIssuerName',
    InstrumentWeightingMethodology = 'Manual/InstrumentWeightingMethodology',
    IsLeveraged = 'Manual/IsLeveraged',
    IsOpenEnded = 'Manual/IsOpenEnded',
    IsSecurityLending = 'Manual/IsSecurityLending',
    IssuanceKey = 'Manual/IssuanceKey',
    Issuer = 'Manual/Issuer',
    ManagedInReferenceToBenchmark = 'Manual/ManagedInReferenceToBenchmark',
    MultreesPSET = 'Manual/MultreesPSET',
    RawId = 'Manual/RawId',
    ReplicationMethod = 'Manual/ReplicationMethod',
    ReplicationSamplingMethod = 'Manual/ReplicationSamplingMethod',
    SubsidiaryIssuer = 'Manual/SubsidiaryIssuer',
    UKReportingStatus = 'Manual/UKReportingStatus',
    UKReportingStatusEndDate = 'Manual/UKReportingStatusEndDate',
    UKReportingStatusStartDate = 'Manual/UKReportingStatusStartDate',
}

export enum EikonInstrumentAttributes {
    ExchangeCountry = 'Eikon/ExchangeCountry',
    GICSSector = 'Eikon/GICSSector',
    GICSIndustry = 'Eikon/GICSIndustry',
    GICSIndustryGroup = 'Eikon/GICSIndustryGroup',
    ForecastDividendYield = "Eikon/ForecastDividendYield",
    FourWeekPriceChange = "Eikon/4WeekPriceChange",
    CompanyMarketCap = "Eikon/CompanyMarketCap",
    ForwardPERatio = 'Eikon/ForwardPERatio',
    YieldToWorst = 'Eikon/YieldToWorstAnalytics',
    YieldToMaturity = 'Eikon/YieldToMaturityAnalytics',
    ModifiedDuration = 'Eikon/ModifiedDurationAnalytics',
    HeadquartersCountry = 'Eikon/HeadquartersCountry',
    FiIssuerName = 'Eikon/FiIssuerName',
    FiSPRating = 'Eikon/SpCreditRating',
    FiFitchsRating = 'Eikon/FiFitchsRating',
    FiMoodysRating = 'Eikon/FiMoodysRating',
    RatingSPEquivalent = 'Eikon/RatingSPEquivalent',
    MaturityDate = 'Eikon/MaturityDate'
}

/*
Contains the fields that are available from the Omba Data stores
 */
export class Attributes{

    static Instrument = class {

        static readonly Refinitiv = RefinitivInstrumentAttributes;
        static readonly Eikon = EikonInstrumentAttributes;
        static readonly Manual = ManualInstrumentAttributes;
    }
}

export default Attributes;
