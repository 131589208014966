enum InstrumentType{
    Equity = "Equity",
    FixedIncome = "Fixed Income",
    Commodity = "Commodity",
    Cash = "Cash",
    RealEstate = "Real Estate",
    MultiAsset = "Multi-Asset",
    PrivateEquity = "Private Equity",
    HedgeFund = "Hedge Fund",
    Cryptocurrency = "Cryptocurrency",
    FXDerivatives = "FX Derivative",
    Alternatives = "Alternatives"
}

export default InstrumentType