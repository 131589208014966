import React from 'react';
import ReportInfo from "../../interfaces/props/views/reportInfo";
import BasicForm from "./forms/basicForm";

function Report(props: ReportInfo){

    const {
        report,
        csvReader,
        formInfo,
    } = props;


    /* The HTML below is from the adminLTE template, some components such as the display boxes have been pulled out into
    their own components to make modifying the view easier.

    Note that the content below consists of four rows.

    1) A Header row
    2) A row containing a PDF document

    Inside each row there can be multiple components each taking up one or more columns.
    */
    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Report</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Report v1</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {BasicForm({...formInfo})}
                        {/* File Upload */}
                        <div className="col-lg-12 col-1">
                            {/* csvReader */}
                        </div>
                        {/* Report */}
                        <div className="col-lg-12 col-1">
                            {report}
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>
    )
}

export default Report;