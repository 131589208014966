import {Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import tree from '../images/Tree.png';
import insideBackCover from '../images/InsideCover.png';
import backCoverWithDisclaimer from '../images/BackCoverWithDisclaimer.png';
import ombaLogo from '../images/OmbaLogo.png';
import Table from "./modules/table";
import Footer from "./modules/portfolioAnalysis/footer";
import PortfolioAnalysisReportInfo from "../interfaces/props/reports/portfolioAnalysisReportInfo";
import Header from "./modules/portfolioAnalysis/header";
import RegisterFonts from "./registerFonts";
const React = require('react');

function PortfolioAnaylsis(props: PortfolioAnalysisReportInfo){

    const {
        client,
        reportDate,
        latestDate,
        currentDate,
        tables,
        charts
    } = props;

    RegisterFonts();

    // Create styles
    const styles = StyleSheet.create({
        // Pages
        coverPage: {
            backgroundColor: '#EFEBE5',
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-end",
            alignItems: "flex-start"
        },
        contentPage: {
            backgroundColor: '#FFFFFF',
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 120,
            paddingTop: 20
        },
        // Test
        coverText: {
            fontFamily: "Merriweather",
            fontWeight: "normal",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 45,
            paddingRight: 45,
            fontSize: 14
        },
        date: {
            fontSize: 8,
            paddingTop: 20
        },
        heading: {
            color: "#1E3922",
            fontFamily: "Merriweather",
            fontWeight: "normal",
            fontSize: 22,
            paddingBottom: 5
        },
        subHeading: {
            color: "#801C1F",
            fontFamily: "Merriweather",
            fontWeight: "semibold",
            fontSize: 14,
            paddingBottom: 10
        },
        title: {
            fontWeight: "semibold",
        },
        standardText: {
            color: "#3C3C3B",
            fontFamily: "Merriweather",
            fontWeight: "light",
            fontSize: 11,
            lineHeight: 1.2,
            paddingBottom: 6
        },
        italicText: {
            fontStyle: "italic",
        },
        // Images
        image: {
            alignContent: 'flex-end',
            flexShrink: 0
        },
        chart: {
            flexGrow: 0,
            flexShrink: 0,
            maxWidth: "100%",
            height: "auto",
            paddingBottom: 10
        },
        logo: {
            flexBasis: "10%",
            alignSelf: 'flex-end'
        },
        half: {
            flexBasis: "50%",
        },
        // Containers
        row: {
            flexDirection: "row",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start"
        },
        column: {
            flexDirection: "column",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: 5,
            paddingBottom: 5
        },
        noShrink: {
            flexShrink: 0
        }}
    );

    return <Document>

        <Page style={styles.coverPage} size="A4">
            <View style={[styles.column, {alignSelf: "flex-end"}]}>
                <Image style={styles.logo} src={ombaLogo}/>
            </View>
            <View style={styles.column}>
                <Text style={styles.coverText}>PORTFOLIO ANALYSIS PREPARED FOR</Text>
                <Text style={styles.coverText}>{client}</Text>
                <Text style={[styles.coverText, styles.date]}>{currentDate}</Text>
            </View>
            <View style={styles.column}>
                <Image style={styles.image} src={tree}/>
            </View>
        </Page>

        <Page wrap style={[styles.contentPage, styles.noShrink]} size="A4">
            <Header/>
            <View style={[styles.column, styles.noShrink]}>
                <Text style={styles.heading}>INVESTMENT PORTFOLIO</Text>
                <Text style={[styles.italicText, styles.standardText]}>Data is as of {reportDate} unless indicated otherwise.</Text>
            </View>
            <View style={[styles.column, styles.noShrink]}>
                <Text style={styles.subHeading}>SUMMARY AND PORTFOLIO VALUATION - LATEST</Text>
                <Text style={styles.standardText}>While most of this document is prepared based on values as of {reportDate},
                    we have updated the values of your portfolio to intra-day {latestDate} so that you have a more recent
                    view of the value of your portfolio.</Text>
                <Table {...tables.clientPositions}/>
            </View>
            <View wrap={false} style={[styles.column, styles.noShrink]}>
                <Text style={styles.subHeading}>SUMMARY AND PORTFOLIO VALUATION</Text>
                <View style={styles.row}>
                    <Image style={[styles.chart, styles.half]} src={charts.clientHoldingsSunburstPlot?.image}/>
                    <View style={[styles.half, {alignSelf: "center"}]}>
                        <Table {...tables.clientPositionsBrief}/>
                    </View>
                </View>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4">
            <Header/>
            <View style={[styles.column, styles.noShrink]}>
                <Text style={[styles.subHeading]}>ETF ANALYSIS - EQUITY ETFS</Text>
                <Text style={[styles.standardText, styles.title]}>Top 10</Text>
                <Text style={[styles.standardText]}>The tables below show the top 10 holdings in each Equity ETF that you own, as well as some
                basic information about each of the holdings.</Text>
                <Table {...tables.top10UnderlyingEquities}/>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4">
            <Header/>
            <View wrap={false} style={[styles.column, styles.noShrink]}>
                <Text style={[styles.standardText, styles.title]}>Exposure by Sector and Industry</Text>
                <Text style={[styles.standardText]}>{charts.etfEquitySectorExposureBarChart?.commentary}</Text>
                <Image style={[styles.image, styles.chart]} src={charts.etfEquitySectorExposureBarChart?.image}/>
            </View>
            <View wrap={false} style={[styles.column, styles.noShrink]}>
                <View style={[styles.row, styles.noShrink]}>
                    <Image style={[styles.image, styles.chart, styles.half]} src={charts.etfEquityIndustryExposureBarChart?.image}/>
                    <Text style={[styles.standardText, styles.half, {alignSelf: "center"}]}>{charts.etfEquityIndustryExposureBarChart?.commentary}</Text>
                </View>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4">
            <Header/>
            <View wrap={false} style={[styles.column, styles.noShrink]}>
                <Text style={[styles.standardText]}>{charts.etfEquitySectorIndustryExposureSunburstChart?.commentary}</Text>
                <View style={[styles.row, {justifyContent: "center"}]}>
                    <Image style={[styles.image, styles.chart, {alignSelf: "flex-start"}]} src={charts.etfEquitySectorIndustryExposureSunburstChart?.image}/>
                </View>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4">
            <Header/>
            <View wrap={false} style={[styles.column, styles.noShrink]}>
                <Text style={[styles.standardText, styles.title]}>Exposure by Company</Text>
                <Text style={[styles.standardText]}>{charts.etfEquityCompanyExposureBarChart?.commentary}</Text>
                <Image style={[styles.image, styles.chart]} src={charts.etfEquityCompanyExposureBarChart?.image}/>
            </View>
            <View wrap={false} style={[styles.column, styles.noShrink]}>
                <Text style={[styles.standardText, styles.title]}>Exposure by Country of Headquarters</Text>
                <Text style={[styles.standardText]}>{charts.etfEquityHeadquartersExposureBarChart?.commentary}</Text>
                <Image style={[styles.image, styles.chart]} src={charts.etfEquityHeadquartersExposureBarChart?.image}/>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4">
            <Header/>
            <View style={[styles.column, styles.noShrink]}>
                <Text style={[styles.standardText, styles.title]}>Exposure by country of Headquarters</Text>
                <Text style={[styles.standardText]}>{charts.etfEquityHeadquartersExposureByEtfBarChart?.commentary}</Text>
                <Image style={[styles.image, styles.chart]} src={charts.etfEquityHeadquartersExposureByEtfBarChart?.image}/>
                <Image style={[styles.image, styles.chart]} src={charts.etfEquityHeadquartersExposureByPortfolioMap?.image}/>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4">
            <Header/>
            <View style={[styles.column, styles.noShrink]}>
                <Text style={[styles.heading]}>ETF ANALYSIS - FIXED INCOME ETFS</Text>
                <Text style={[styles.standardText, styles.title]}>Blended ETF Stats</Text>
                {tables.clientPositionsFixedIncomeBrief.map(row => {
                    return <Text style={[styles.standardText]}>{row[0]} - {row[1]}</Text>
                })}
                <Table {...tables.clientPositionsFixedIncome}/>
            </View>
            <View style={[styles.column, styles.noShrink]}>
                <Text style={[styles.standardText, styles.title]}>Exposure by Credit Rating</Text>
                <Text style={[styles.standardText]}>{charts.etfFixedIncomeCreditRatingExposureBarChart?.commentary}</Text>
                <Image style={[styles.image, styles.chart]} src={charts.etfFixedIncomeCreditRatingExposureBarChart?.image}/>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.contentPage} size="A4">
            <Header/>
            <View style={[styles.column, styles.noShrink]}>
                <Text style={[styles.heading]}>OVERLAP BETWEEN ETFS (EQUITY)</Text>
                <Text style={[styles.standardText]}>{charts.etfOverlapChart?.commentary}</Text>
                <Image style={[styles.image, styles.chart]} src={charts.etfOverlapChart?.image}/>
            </View>
            <Footer/>
        </Page>

        <Page style={styles.coverPage} size="A4">
            <Image style={styles.image} src={insideBackCover}/>
        </Page>

        <Page style={styles.coverPage} size="A4">
            <Image style={styles.image} src={backCoverWithDisclaimer}/>
        </Page>

    </Document>


}

export default PortfolioAnaylsis;