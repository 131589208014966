import ReferenceDataStore from "./referenceDataStore";
import InstrumentReferenceData from "../instrumentReferenceData";

class EikonReferenceDataStore implements ReferenceDataStore{

    baseApiUrl: string;

    constructor(baseApiUrl: string){
        this.baseApiUrl = baseApiUrl;
    }

    getInstrumentReferenceData(
        instrumentIds: string[],
        referenceDataFields: any
    ): Promise<InstrumentReferenceData>{
        return fetch(`${this.baseApiUrl}/instruments/`,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                "instrumentIdentifiers": instrumentIds,
                "referenceDataFields": referenceDataFields
            })
        })
            .then(response => response.json())
            .then(res => {
                return new InstrumentReferenceData(res)
            });
    }
}

export default EikonReferenceDataStore;