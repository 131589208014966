class Attribute{

    name: string;

    source: string;

    dataType: string;

    description: string;

    constructor(name: string, source: string, dataType: string, description: string){
        this.name = name;
        this.source = source;
        this.dataType = dataType;
        this.description = description;
    }
}

export default Attribute;