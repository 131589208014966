import Instrument from "../instrument";
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import OmbaInstrumentStore from "./ombaInstrumentStore";
import EikonReferenceDataStore from "../../referenceData/stores/eikonReferenceDataStore";
import InstrumentReferenceData from "../../referenceData/instrumentReferenceData";
import Attributes, {EikonInstrumentAttributes} from "../../../interfaces/enums/attributes"
import ReferenceDataFields from "../../../interfaces/enums/referenceDataFields";
import OmbaInstrument from "../../../interfaces/ombaApi/ombaInstrument";


/*
The OMBA Instrument Store which stores the Instruments in the OMBA Instrument Store via the OMBA APIs
 */
class OmbaInstrumentStoreWithEikonEnrichment extends OmbaInstrumentStore {

    eikonReferenceDataStore: EikonReferenceDataStore;
    referenceDataFieldToAttributeMapping: {[referenceDataField: string]: string};

    /*
    If we have a map of key/value pairs this function inverts it to produce a map of value/key pairs. If there are
    multiple instances of the same value against different keys the last write wins so this kind of relies on the
    assumption that not only are the keys unique but the values are too.
     */
    private static produceReverseMap(forwardMap: {[attribute: string]: any}): {[referenceDataField: string]: string} {

        let reverseMap: {[referenceDataField: string]: string} = {};

        for (var key in forwardMap) {
            if (forwardMap.hasOwnProperty(key)) {
                reverseMap[forwardMap[key]] = key;
            }
        }

        return reverseMap;
    }

    /*
    Contains a mapping of reference data fields to the parameters provided when retrieving these fields. For some
    fields it is just the name of the field itself, for other fields it is a nested object with paramters.
     */
    private static referenceDataFieldToInputsMapping(asOfDate: string, currency: string): {[attribute: string]: any} {

        return {
            [ReferenceDataFields.Instrument.Eikon.HeadquartersCountry]: ReferenceDataFields.Instrument.Eikon.HeadquartersCountry,
            [ReferenceDataFields.Instrument.Eikon.ExchangeCountry]: ReferenceDataFields.Instrument.Eikon.ExchangeCountry,
            [ReferenceDataFields.Instrument.Eikon.GICSSector]: ReferenceDataFields.Instrument.Eikon.GICSSector,
            [ReferenceDataFields.Instrument.Eikon.GICSIndustryGroup]: ReferenceDataFields.Instrument.Eikon.GICSIndustryGroup,
            [ReferenceDataFields.Instrument.Eikon.GICSIndustry]: ReferenceDataFields.Instrument.Eikon.GICSIndustry,
            [ReferenceDataFields.Instrument.Eikon.FundDomicile]: ReferenceDataFields.Instrument.Eikon.FundDomicile,
            [ReferenceDataFields.Instrument.Eikon.FundTER]: ReferenceDataFields.Instrument.Eikon.FundTER,
            [ReferenceDataFields.Instrument.Eikon.FiIssuerName]: ReferenceDataFields.Instrument.Eikon.FiIssuerName,
            [ReferenceDataFields.Instrument.Eikon.PricePercentageFourWeekChange]: ReferenceDataFields.Instrument.Eikon.PricePercentageFourWeekChange,

            [ReferenceDataFields.Instrument.Eikon.ForwardPERatio]: {
                [ReferenceDataFields.Instrument.Eikon.ForwardPERatio]: {
                    'params': {
                        'Period':'NTM',
                        'SDate': asOfDate
                    }
                }
            },

            [ReferenceDataFields.Instrument.Eikon.DPSSmartYield]: {
                [ReferenceDataFields.Instrument.Eikon.DPSSmartYield]: {
                    'params': {
                        'SDate': asOfDate,
                        'Period': 'NTM'
                    }
                }
            },

            [ReferenceDataFields.Instrument.Eikon.CompanyMarketCap]: {
                [ReferenceDataFields.Instrument.Eikon.CompanyMarketCap]: {
                    'params': {
                        'Curn': currency,
                        'SDate': asOfDate,
                        'ShType': 'OUT'}
                }
            }, // 'ShType': 'FFL' vs 'OUT'?

            [ReferenceDataFields.Instrument.Eikon.YieldToWorstAnalytics]: {
                [ReferenceDataFields.Instrument.Eikon.YieldToWorstAnalytics]: {
                    'params': {
                        'ValuationDate': asOfDate,
                    }
                }
            },
            [ReferenceDataFields.Instrument.Eikon.YieldToMaturityAnalytics]: {
                [ReferenceDataFields.Instrument.Eikon.YieldToMaturityAnalytics]: {
                    'params': {
                        'ValuationDate': asOfDate,
                    }
                }
            },

            [ReferenceDataFields.Instrument.Eikon.ModifiedDurationAnalytics]: {
                [ReferenceDataFields.Instrument.Eikon.ModifiedDurationAnalytics]: {
                    'params': {
                        'ValuationDate': asOfDate,
                    }
                }
            },

            [ReferenceDataFields.Instrument.Eikon.RatingSPEquivalent]: {
                [ReferenceDataFields.Instrument.Eikon.RatingSPEquivalent]: {
                    'params': {
                        'SDate': asOfDate,
                        'Filling': 'Prev'
                    }
                }
            },

            [ReferenceDataFields.Instrument.Eikon.FiMoodysRating]: ReferenceDataFields.Instrument.Eikon.FiMoodysRating,
            [ReferenceDataFields.Instrument.Eikon.FiFitchsRating]: ReferenceDataFields.Instrument.Eikon.FiFitchsRating,
            [ReferenceDataFields.Instrument.Eikon.FiSPRating]: ReferenceDataFields.Instrument.Eikon.FiSPRating,
        }
    }

    /*
    Contains a mapping of instrument attributes between the Omba Instrument Store and instrument Reference Data
    sourced from Eikon
     */
    private static readonly instrumentAttributeToReferenceDataFieldMapping: {[attribute: string]: string} = {
        [Attributes.Instrument.Refinitiv.InstrumentDomicile]: ReferenceDataFields.Instrument.Eikon.FundDomicile,
        [Attributes.Instrument.Refinitiv.TotalExpenseRatio]: ReferenceDataFields.Instrument.Eikon.FundTER,

        [Attributes.Instrument.Eikon.HeadquartersCountry]: ReferenceDataFields.Instrument.Eikon.HeadquartersCountry,
        [Attributes.Instrument.Eikon.ExchangeCountry]: ReferenceDataFields.Instrument.Eikon.ExchangeCountry,
        [Attributes.Instrument.Eikon.GICSSector]: ReferenceDataFields.Instrument.Eikon.GICSSector,
        [Attributes.Instrument.Eikon.GICSIndustryGroup]: ReferenceDataFields.Instrument.Eikon.GICSIndustryGroup,
        [Attributes.Instrument.Eikon.GICSIndustry]: ReferenceDataFields.Instrument.Eikon.GICSIndustry,
        [Attributes.Instrument.Eikon.FiIssuerName]: ReferenceDataFields.Instrument.Eikon.FiIssuerName,
        [Attributes.Instrument.Eikon.ForwardPERatio]: ReferenceDataFields.Instrument.Eikon.ForwardPERatio,
        [Attributes.Instrument.Eikon.ForecastDividendYield]: ReferenceDataFields.Instrument.Eikon.DPSSmartYield,
        [Attributes.Instrument.Eikon.FourWeekPriceChange]: ReferenceDataFields.Instrument.Eikon.PricePercentageFourWeekChange,
        [Attributes.Instrument.Eikon.CompanyMarketCap]: ReferenceDataFields.Instrument.Eikon.CompanyMarketCap,
        [Attributes.Instrument.Eikon.YieldToWorst]: ReferenceDataFields.Instrument.Eikon.YieldToWorstAnalytics,
        [Attributes.Instrument.Eikon.YieldToMaturity]: ReferenceDataFields.Instrument.Eikon.YieldToMaturityAnalytics,
        [Attributes.Instrument.Eikon.ModifiedDuration]: ReferenceDataFields.Instrument.Eikon.ModifiedDurationAnalytics,
        [Attributes.Instrument.Eikon.FiMoodysRating]: ReferenceDataFields.Instrument.Eikon.FiMoodysRating,
        [Attributes.Instrument.Eikon.FiSPRating]: ReferenceDataFields.Instrument.Eikon.FiSPRating,
        [Attributes.Instrument.Eikon.FiFitchsRating]: ReferenceDataFields.Instrument.Eikon.FiFitchsRating,
        [Attributes.Instrument.Eikon.RatingSPEquivalent]: ReferenceDataFields.Instrument.Eikon.RatingSPEquivalent
    };

    constructor(
        baseApiUrl: string,
        account: AccountInfo,
        instance: IPublicClientApplication,
        eikonReferenceDataStore: EikonReferenceDataStore
    ){
        super(baseApiUrl, account, instance);
        this.eikonReferenceDataStore = eikonReferenceDataStore;
        this.referenceDataFieldToAttributeMapping = OmbaInstrumentStoreWithEikonEnrichment.produceReverseMap(OmbaInstrumentStoreWithEikonEnrichment.instrumentAttributeToReferenceDataFieldMapping)
    }

    getInstrument(instrumentId: string, attributes: string[]): Promise<Instrument> {

        return super.getInstrument(instrumentId, attributes)
    }

    getInstruments(instrumentIds: string[], attributes: string[], asOfDate?: string, currency?: string): Promise<Instrument[]> {

        return super.getInstruments(instrumentIds, attributes).then(instruments => {

            const instrumentRics: string[] = instruments.map(instrument => {
                return instrument?.identifiers?.KeyRIC || "Unknown"
            }).filter(ric => ric != "Unknown");

            const referenceDataFieldsMapping = OmbaInstrumentStoreWithEikonEnrichment
                .referenceDataFieldToInputsMapping(asOfDate || "2020-01-01", currency || "USD");

            let instrumentResponses = [];

            const instrumentsPerRequest = 5000;

            for (let i = 0; i < instrumentIds.length; i += instrumentsPerRequest) {

                instrumentResponses.push(this.eikonReferenceDataStore.getInstrumentReferenceData(
                    instrumentRics.slice(i, i + instrumentsPerRequest),
                    attributes.map(attribute => referenceDataFieldsMapping[OmbaInstrumentStoreWithEikonEnrichment.instrumentAttributeToReferenceDataFieldMapping[attribute]])
                ).then((instrumentReferenceData) => {

                    return instruments.map(instrument => {
                        return this.enrichInstrument(instrument, instrumentReferenceData)
                    })
                }));
            }

            return Promise.all(instrumentResponses).then((values) => values.flat());
        })
    }

    private enrichInstrument(instrument: Instrument, instrumentReferenceData: InstrumentReferenceData): Instrument {

        if (!((instrument?.identifiers?.KeyRIC || "Unknown") in instrumentReferenceData.referenceData)){
            return instrument;
        }
        const referenceData = instrumentReferenceData.referenceData[instrument?.identifiers?.KeyRIC];

        if (instrument.attributes == null) {
            instrument.attributes = {}
        }

        if (instrument.attributes["Refinitiv"] == null) {
            instrument.attributes["Refinitiv"] = {}
        }

        if (instrument.attributes["Manual"] == null) {
            instrument.attributes["Manual"] = {}
        }

        if (instrument.attributes["Eikon"] == null) {
            instrument.attributes["Eikon"] = {}
        }

        Object.entries(referenceData).forEach(([key, value]) => {

            if (!(key in this.referenceDataFieldToAttributeMapping)){
                return
            }

            let instrumentAttribute = this.referenceDataFieldToAttributeMapping[key];
            let instrumentAttributeSource: string = instrumentAttribute.split("/")[0];
            let instrumentAttributeType: string = instrumentAttribute.split("/")[1];

            if (instrument.attributes == null) {
                instrument.attributes = {}
            }

            instrument.attributes[instrumentAttributeSource][instrumentAttributeType] = value;
        });

        // Take values from reference data and paint onto instrument as appropriate attribute
        return instrument;
    }
}

export default OmbaInstrumentStoreWithEikonEnrichment;