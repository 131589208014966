import React from "react";
import DataTableData from "../../interfaces/datatable";
import TableRow from "./tableRow";
import Column from "../../interfaces/column";
import TableHeader from "./tableHeader";
import { StyleSheet, View } from '@react-pdf/renderer';


function Table(props: DataTableData){

    // Create styles
    const styles = StyleSheet.create({
        table: {
            paddingTop: 20,
            paddingBottom: 45,
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            flexShrink: 0
        },
        row: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            backgroundColor: '#FFFFFF',
        },
        cell: {
            paddingLeft: 10,
            paddingRight: 10,
            color: '#3C3C3B',
            fontSize: 6,
            paddingTop: 4,
            fontFamily: "Arial"
        },
        headerRow: {
            backgroundColor: '#525E48',
        },
        headerEntry: {
            backgroundColor: '#525E48',
            color: '#F5F2ED',
            textAlign: "center",
            alignSelf: "center",
            fontSize: 6,
            paddingTop: 5,
            paddingBottom: 5,
            fontFamily: "Arial"
        }}
    )
    
    const columns: Column[] = props.columns;
    const rows: { [source: string] : {[attribute: string] : string}}[] = props.rows;

    const tableRows = rows.map((row, index) => {
        return <TableRow key={index} columns={columns} row={row} rowStyles={styles.row} cellStyles={styles.cell}/>
    });

    return <View style={styles.table}>
        <TableHeader columns={columns} rowStyles={styles.row} cellStyles={styles.cell} headerRowStyles={styles.headerRow} headerCellStyles={styles.headerEntry}/>
        {tableRows}
    </View>
}

export default Table;