class Identifier{

    name: string;

    isUnique: boolean;

    constructor(name: string, isUnique: boolean){
        this.name = name;
        this.isUnique = isUnique;
    }
}

export default Identifier;