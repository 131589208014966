enum EikonInstrumentReferenceData {

    FundName = "TR.FundName",
    InstrumentDescription = "TR.InstrumentDescription",
    FundType = "TR.FundType",
    FundObjective = "TR.FundObjective",
    RDNExchangeCode = "TR.RDNExchangeCode",
    TickerSymbol = "TR.TickerSymbol",
    PrimaryIssueRic = "TR.PrimaryIssueRICCode",
    ISIN = "TR.ISIN",
    SEDOL = "TR.SEDOL",
    FundTER = "TR.FundTER",
    FundTERDate = "TR.FundTERDate",
    FundBenchmarkName = "TR.FundBenchmarkName",
    FundBenchmarkRic = "TR.FundBenchmarkInstrumentRIC",
    PriceClose = "TR.PriceClose",
    PriceCloseCurrency = "TR.PriceClose.currency",
    FundNAV = "TR.FundNAV",
    FundNAVCurrency = "TR.FundNAV.currency",
    FundGeographicFocus = "TR.FundGeographicFocus",
    FundLegalStructure = "TR.FundLegalStructure",
    FundCustodian = "TR.FundCustodian",
    FundDomicile = "TR.FundDomicile",
    FundIncDistributionIndicator = "TR.FundIncDistributionIndicator",
    DividendYield = "TR.DividendYield",
    FundNumberOfDividendPaymentPerYear = "TR.FundNumberOfDividendPaymentPerYear",
    DividendCurrency = "TR.DivCurr",
    FundExDate = "TR.FundExDate",
    FundPayDate = "TR.FundPayDate",
    FundLaunchDate = "TR.FundLaunchDate",
    AverageDailyValTraded30D = "TR.AvgDailyValTraded30D",
    ETPDeltaOneAssetsUnderManagement = "TR.ETPDeltaOneAssetsUnderManagement",
    ETPDeltaOneSharesOutstanding = "TR.ETPDeltaOneSharesOutstanding",
    ETPCreationUnits = "TR.ETPCreationUnits",
    FundTrackingError1Year = "TR.FundTrackingError1Year",
    FundTrackingError3Year = "TR.FundTrackingError3Year",
    FundTrackingError5Year = "TR.FundTrackingError5Year",
    FundTrackingError10Year = "TR.FundTrackingError10Year",
    RIC = "TR.RIC",
    InstrumentName = 'TR.InstrumentName',
    InstrumentType =  "TR.InstrumentType",
    HeadquartersCountry = "TR.HeadquartersCountry",
    ExchangeCountry = "TR.ExchangeCountry",
    GICSSector = "TR.GICSSector",
    GICSIndustryGroup = "TR.GICSIndustryGroup",
    GICSIndustry = "TR.GICSIndustry",
    CompanyMarketCap = "TR.CompanyMarketCap",
    DPSSmartYield = "TR.DPSSmartYield",
    PricePercentage5DayChangeDate = "TR.PricePctChg5D.date",
    PricePercentage5DayChange = "TR.PricePctChg5D",
    PricePercentageFourWeekChange = "TR.PricePctChg4W",
    PricePctChgRelSect1M = "TR.PricePctChgRelSect1M",
    PricePctChg3M = 'TR.PricePctChg3M',
    PricePctChg52WkHigh = 'TR.PricePctChg52WkHigh',
    PricePctChg52WkLow = 'TR.PricePctChg52WkLow',
    BetaFiveYear = 'TR.BetaFiveYear',
    RSISimple14D = 'TR.RSISimple14D',
    EPSPctNumEstRevisingUp = 'TR.EPSPctNumEstRevisingUp',
    PE = 'TR.PE',
    ForwardPERatio = "TR.FwdPE",
    PEGSmart = 'TR.PEGSmart',
    HistPEExclExtraItemsAvgDilutedSharesOut5YrAvg = 'TR.HistPEExclExtraItemsAvgDilutedSharesOut5YrAvg',
    PriceToSalesPerShare = 'TR.PriceToSalesPerShare',
    PriceToBVPerShare = 'TR.PriceToBVPerShare',
    EVToOperatingCashFlow = 'TR.EVToOperatingCashFlow',
    GrossMargin ='TR.GrossMargin',
    TtlDebtToTtlEquityPct = 'TR.TtlDebtToTtlEquityPct',
    AvgDailyValTraded30D ='TR.AvgDailyValTraded30D',
    SIShortInterest = 'TR.SIShortInterest',
    StateOwnedEnterpriseSOE = 'TR.StateOwnedEnterpriseSOE',
    PreferredRIC = 'TR.PreferredRIC',
    FiIssuerName = 'TR.FiIssuerName',
    FiCouponFrequency = 'TR.FiCouponFrequency',
    FiCouponClassDescription = 'TR.FiCouponClassDescription',
    FiIssueDate = 'TR.FiIssueDate',
    FiMaturityDate = 'TR.FiMaturityDate',
    GreenBondFlag = 'TR.GreenBondFlag',
    FiIssuerCountryName = 'TR.FiIssuerCountryName',
    FiCurrency = 'TR.FiCurrency',
    FiAssetTypeDescription = 'TR.FiAssetTypeDescription',
    FiSeniorityTypeShortDescription = 'TR.FiSeniorityTypeShortDescription',
    FiIndustrySectorDescription = 'TR.FiIndustrySectorDescription',
    FiIndustrySubSectorDescription = 'TR.FiIndustrySubSectorDescription',
    FiFitchsRating = 'TR.FiFitchsRating',
    FiSPRating = 'TR.FiSPRating',
    FiMoodysRating = 'TR.FiMoodysRating',
    RatingSPEquivalent = 'TR.GR.RatingSPEquivalent',
    YieldToWorstAnalytics= 'TR.YieldToWorstAnalytics',
    YieldToMaturityAnalytics = 'TR.YieldToMaturityAnalytics',
    ModifiedDurationAnalytics = 'TR.ModifiedDurationAnalytics',
    CSRReportingScope = 'TR.CSRReportingScope',
    TRESGSCOREPeriodenddate ='TR.TRESGSCORE.Periodenddate',
    ESGPeriodLastUpdateDate ='TR.ESGPeriodLastUpdateDate',
    TRESGCScore = 'TR.TRESGCScore',
    TRESGScore = 'TR.TRESGScore',
    EnvironmentPillarScore = 'TR.EnvironmentPillarScore',
    SocialPillarScore = 'TR.SocialPillarScore',
    GovernancePillarScore = 'TR.GovernancePillarScore',
    TRESGCControversiesScore = 'TR.TRESGCControversiesScore',
    TRESGEmissionsScore = 'TR.TRESGEmissionsScore',
    CO2EmissionTotal = 'TR.CO2EmissionTotal',
    CO2DirectScope1 = 'TR.CO2DirectScope1',
    CO2IndirectScope2 = 'TR.CO2IndirectScope2',
    CO2IndirectScope3 = 'TR.CO2IndirectScope3',
    AnalyticCO2 = 'TR.AnalyticCO2',
    AnalyticEnergyUse = 'TR.AnalyticEnergyUse',
    AnalyticRenewEnergyUse = 'TR.AnalyticRenewEnergyUse',
    RenewEnergyProduced = 'TR.RenewEnergyProduced',
    AnalyticWaterUse = 'TR.AnalyticWaterUse',
    GenderPayGapPctage = 'TR.GenderPayGapPctage',
    WomenEmployees = 'TR.WomenEmployees',
    AnalyticBoardFemale = 'TR.AnalyticBoardFemale'
}

/*
Contains the fields that are available from the Reference Data stores
 */
class ReferenceDataFields{

    static Instrument = class {

        static readonly Eikon = EikonInstrumentReferenceData;
    }

}

export default ReferenceDataFields;
