import InstrumentType from "../../interfaces/enums/instrumentType";
import {
    EikonInstrumentAttributes,
    ManualInstrumentAttributes,
    RefinitivInstrumentAttributes
} from "../../interfaces/enums/attributes";
import prettify from "../../utilities/prettify";

class Instrument{

    name: string;

    type: InstrumentType;

    currency: string;

    status?: string;

    description?: string;

    ombaInstrumentId?: number;

    identifiers: { [identifierType: string]: string };

    attributes?: { [source: string] : {[attribute: string] : string}};


    constructor(name: string, type: InstrumentType, currency: string, identifiers: { [identifierType: string]: string },
            attributes?:  { [source: string] : {[attribute: string] : string}}, status?: string,
            description?: string, ombaInstrumentId?: number){
        this.name = name;
        this.type = type;
        this.currency = currency;
        this.status = status;
        this.description = description;
        this.ombaInstrumentId = ombaInstrumentId;
        this.identifiers = identifiers;
        this.attributes = attributes;
    }

    getAttributeValue(
        attribute: RefinitivInstrumentAttributes | EikonInstrumentAttributes | ManualInstrumentAttributes,
        defaultValue: string = "Not Specified"){

        if (!this.attributes){
            return defaultValue;
        }

        let attribute_source = attribute.split("/")[0];
        let attribute_key = attribute.split("/")[1];

        if (!(attribute_source in this.attributes)){
            return defaultValue;
        }

        if (!(attribute_key in this.attributes[attribute_source])){
            return defaultValue;
        }

        if (this.attributes[attribute_source][attribute_key] === null){
            return defaultValue;
        }

        return this.attributes[attribute_source][attribute_key];
    }

    getAttributeValueWaterfall(
        attributeWaterfall: (RefinitivInstrumentAttributes | EikonInstrumentAttributes | ManualInstrumentAttributes)[],
        defaultValue: string = "Not Specified"
    ){

        let attributeValue = defaultValue;

        attributeWaterfall.some(attribute => {
            let currentAttributeValue = this.getAttributeValue(attribute, defaultValue);
            if (currentAttributeValue !== defaultValue){
                attributeValue = currentAttributeValue;
                return true;
            }
        });

        return attributeValue;
    }


}

export default Instrument;