import React from 'react';
import {MDBDataTableV5} from 'mdbreact';
import DataTableInfo from "../../interfaces/props/views/dataTableInfo";


export default function BasicTable(props: DataTableInfo) {

    const {
        data,
        entries,
        onSearch,
        onPageChange
    } = props

    return <div>
        <MDBDataTableV5 hover
            entriesOptions={[5, 10, 25, 100, 1000]}
            entries={entries}
            data={data}
            fixed={true}
            responsive={true}
            onSearch={onSearch}
            onPageChange={onPageChange}
        />
    </div>;
}