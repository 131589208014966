import React from 'react';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import SignInOutPageInfo from "../interfaces/props/views/signInOutPageInfo";


function SignInPage(props: SignInOutPageInfo){

    const {
        signInOutAction,
        action
    } = props;

    return (
        <div>
            <div className="content-wrapper">
            <DropdownButton variant="secondary" className="ml-auto" drop="right" title={action}>
                <Dropdown.Item as="button" onClick={() => signInOutAction.action("popup")}>{action} using Popup</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => signInOutAction.action("redirect")}>{action} using Redirect</Dropdown.Item>
            </DropdownButton>
            </div>
        </div>
    )
}

export default SignInPage;