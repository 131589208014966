const CountryMapping: {[country: string]: {[field: string]: string}} = {
    "Albania": {
        "SimplifiedName":"Albania",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Andorra": {
        "SimplifiedName":"Andorra",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Angola": {
        "SimplifiedName":"Angola",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Argentina": {
        "SimplifiedName":"Argentina",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Armenia": {
        "SimplifiedName":"Armenia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Azerbaijan": {
        "SimplifiedName":"Azerbaijan",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Australia": {
        "SimplifiedName":"Australia",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Austria": {
        "SimplifiedName":"Austria",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Bahamas": {
        "SimplifiedName":"Bahamas",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Bahrain": {
        "SimplifiedName":"Bahrain",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Barbados": {
        "SimplifiedName":"Barbados",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Belarus": {
        "SimplifiedName":"Belarus",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Belgium": {
        "SimplifiedName":"Belgium",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Belize": {
        "SimplifiedName":"Belize",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Benin": {
        "SimplifiedName":"Benin",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Bermuda": {
        "SimplifiedName":"Bermuda",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Bulgaria": {
        "SimplifiedName":"Bulgaria",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Burundi": {
        "SimplifiedName":"Burundi",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Bolivia": {
        "SimplifiedName":"Bolivia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Brazil": {
        "SimplifiedName":"Brazil",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Virgin Islands (British)": {
        "SimplifiedName":"British Virgin Islands",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Virgin Islands; British": {
        "SimplifiedName":"British Virgin Islands",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "British Virgin Islands": {
        "SimplifiedName":"British Virgin Islands",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Canada": {
        "SimplifiedName":"Canada",
            "Region":"Americas",
            "Development":"Developed",
            "RegionDevelopment":"Developed Americas"},
    "Cambodia": {
        "SimplifiedName":"Cambodia",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Cameroon": {
        "SimplifiedName":"Cameroon",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Cayman Islands": {
        "SimplifiedName":"Cayman Islands",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Chile": {
        "SimplifiedName":"Chile",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "China": {
        "SimplifiedName":"China (mainland)",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "China (mainland)": {
        "SimplifiedName":"China (mainland)",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Colombia": {
        "SimplifiedName":"Colombia",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Costa Rica": {
        "SimplifiedName":"Costa Rica",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Croatia": {
        "SimplifiedName":"Croatia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Curacao": {
        "SimplifiedName":"Curacao",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Cyprus": {
        "SimplifiedName":"Cyprus",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Czech Republic": {
        "SimplifiedName":"Czech Republic",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Denmark": {
        "SimplifiedName":"Denmark",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Dominican Republic": {
        "SimplifiedName":"Dominican Republic",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Egypt": {
        "SimplifiedName":"Egypt",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Ecuador": {
        "SimplifiedName":"Ecuador",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "El Salvador": {
        "SimplifiedName":"El Salvador",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Estonia": {
        "SimplifiedName":"Estonia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Ethiopia": {
        "SimplifiedName":"Ethiopia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Faroe Islands": {
        "SimplifiedName":"Faroe Islands",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Finland": {
        "SimplifiedName":"Finland",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "France": {
        "SimplifiedName":"France",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Gabon": {
        "SimplifiedName":"Gabon",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Ghana": {
        "SimplifiedName":"Ghana",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Georgia": {
        "SimplifiedName":"Georgia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Germany": {
        "SimplifiedName":"Germany",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Greece": {
        "SimplifiedName":"Greece",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Guernsey": {
        "SimplifiedName":"Guernsey",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Guatemala": {
        "SimplifiedName":"Guatemala",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Gibraltar": {
        "SimplifiedName":"Gibraltar",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Honduras": {
        "SimplifiedName":"Honduras",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Hong Kong": {
        "SimplifiedName":"Hong Kong",
            "Region":"Asia Pacific",
            "Development":"Developed",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Hungary": {
        "SimplifiedName":"Hungary",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Iceland": {
        "SimplifiedName":"Iceland",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "India": {
        "SimplifiedName":"India",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Indonesia": {
        "SimplifiedName":"Indonesia",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Iraq": {
        "SimplifiedName":"Iraq",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Ireland; Republic of": {
        "SimplifiedName":"Ireland",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Ireland": {
        "SimplifiedName":"Ireland",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Isle of Man": {
        "SimplifiedName":"Isle of Man",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Israel": {
        "SimplifiedName":"Israel",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Italy": {
        "SimplifiedName":"Italy",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Ivory Coast / Cote d'Ivoire": {
        "SimplifiedName":"Ivory Coast / Cote d'Ivoire",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Jamaica": {
        "SimplifiedName":"Jamaica",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Japan": {
        "SimplifiedName":"Japan",
            "Region":"Asia Pacific",
            "Development":"Developed",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Jersey": {
        "SimplifiedName":"Jersey",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Jordan": {
        "SimplifiedName":"Jordan",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Kazakhstan": {
        "SimplifiedName":"Kazakhstan",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Kenya": {
        "SimplifiedName":"Kenya",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Korea; Republic (S. Korea)": {
        "SimplifiedName":"South Korea",
            "Region":"Asia Pacific",
            "Development":"Developed",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Kuwait": {
        "SimplifiedName":"Kuwait",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Latvia": {
        "SimplifiedName":"Latvia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Lebanon": {
        "SimplifiedName":"Lebanon",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Liechtenstein": {
        "SimplifiedName":"Liechtenstein",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Lithuania": {
        "SimplifiedName":"Lithuania",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Luxembourg": {
        "SimplifiedName":"Luxembourg",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Macau": {
        "SimplifiedName":"Macau",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Macao": {
        "SimplifiedName":"Macau",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Malaysia": {
        "SimplifiedName":"Malaysia",
            "Region":"Asia Pacific",
            "Development":"Developed",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Malta": {
        "SimplifiedName":"Malta",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Mauritius": {
        "SimplifiedName":"Mauritius",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Mexico": {
        "SimplifiedName":"Mexico",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Monaco": {
        "SimplifiedName":"Monaco",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Mongolia": {
        "SimplifiedName":"Mongolia",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Montenegro": {
        "SimplifiedName":"Montenegro",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Morocco": {
        "SimplifiedName":"Morocco",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Mozambique": {
        "SimplifiedName":"Mozambique",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Namibia": {
        "SimplifiedName":"Namibia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "nan": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "Netherlands": {
        "SimplifiedName":"Netherlands",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "New Zealand": {
        "SimplifiedName":"New Zealand",
            "Region":"Asia Pacific",
            "Development":"Developed",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Nigeria": {
        "SimplifiedName":"Nigeria",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Norway": {
        "SimplifiedName":"Norway",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "North Macedonia": {
        "SimplifiedName":"North Macedonia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Not Signed In": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "<N/A>": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "<NA>": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "Not specified": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "<N\\/A>": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "NULL": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "Unable to collect data for the field \"TR.HeadquartersCountry\" and some specific identifier(s).": {
        "SimplifiedName":"Not specified",
            "Region":"Not specified",
            "Development":"Not specified",
            "RegionDevelopment":"Not specified"},
    "Oman": {
        "SimplifiedName":"Oman",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Pakistan": {
        "SimplifiedName":"Pakistan",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Panama": {
        "SimplifiedName":"Panama",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Papua New Guinea": {
        "SimplifiedName":"Papua New Guinea",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Paraguay": {
        "SimplifiedName":"Paraguay",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Puerto Rico": {
        "SimplifiedName":"Puerto Rico",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Peru": {
        "SimplifiedName":"Peru",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Philippines": {
        "SimplifiedName":"Philippines",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Poland": {
        "SimplifiedName":"Poland",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Portugal": {
        "SimplifiedName":"Portugal",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Romania": {
        "SimplifiedName":"Romania",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Russia": {
        "SimplifiedName":"Russia",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Russian Federation": {
        "SimplifiedName":"Russia",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Rwanda": {
        "SimplifiedName":"Rwanda",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Saudi Arabia": {
        "SimplifiedName":"Saudi Arabia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Senegal": {
        "SimplifiedName":"Senegal",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Serbia": {
        "SimplifiedName":"Serbia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Singapore": {
        "SimplifiedName":"Singapore",
            "Region":"Asia Pacific",
            "Development":"Developed",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Slovenia": {
        "SimplifiedName":"Slovenia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Slovakia": {
        "SimplifiedName":"Slovakia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "South Africa": {
        "SimplifiedName":"South Africa",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "South Korea": {
        "SimplifiedName":"South Korea",
            "Region":"Asia Pacific",
            "Development":"Developed",
            "RegionDevelopment":"Developed Asia Pacific"},
    "Spain": {
        "SimplifiedName":"Spain",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Sri Lanka": {
        "SimplifiedName":"Sri Lanka",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Sweden": {
        "SimplifiedName":"Sweden",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Switzerland": {
        "SimplifiedName":"Switzerland",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "Suriname": {
        "SimplifiedName":"Suriname",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Taiwan": {
        "SimplifiedName":"Taiwan",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Tajikistan": {
        "SimplifiedName":"Tajikistan",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Thailand": {
        "SimplifiedName":"Thailand",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Trinidad and Tobago": {
        "SimplifiedName":"Trinidad and Tobago",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Togo": {
        "SimplifiedName":"Togo",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Tunisia": {
        "SimplifiedName":"Tunisia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Markets"},
    "Turkey": {
        "SimplifiedName":"Turkey",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Ukraine": {
        "SimplifiedName":"Ukraine",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "United Arab Emirates": {
        "SimplifiedName":"United Arab Emirates",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "United Kingdom": {
        "SimplifiedName":"United Kingdom",
            "Region":"Europe, Middle East and Africa",
            "Development":"Developed",
            "RegionDevelopment":"Developed Europe & Middle East"},
    "United States of America": {
        "SimplifiedName":"United States",
            "Region":"Americas",
            "Development":"Developed",
            "RegionDevelopment":"Developed Americas"},
    "United States": {
        "SimplifiedName":"United States",
            "Region":"Americas",
            "Development":"Developed",
            "RegionDevelopment":"Developed Americas"},
    "Uruguay": {
        "SimplifiedName":"Uruguay",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Uzbekistan": {
        "SimplifiedName":"Uzbekistan",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Venezuela": {
        "SimplifiedName":"Venezuela",
            "Region":"Americas",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Vietnam": {
        "SimplifiedName":"Vietnam",
            "Region":"Asia Pacific",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Qatar": {
        "SimplifiedName":"Qatar",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"},
    "Zambia": {
        "SimplifiedName":"Zambia",
            "Region":"Europe, Middle East and Africa",
            "Development":"Emerging",
            "RegionDevelopment":"Emerging Market"}
};

export default CountryMapping;