import FormModalInfo from "../../interfaces/props/views/formModalInfo";
import BasicForm from "../adminLTE/forms/basicForm";

export default function BasicFormModal(props: FormModalInfo ){

    const {
        modalId,
        modalTitle,
        formInfo
    } = props;

    return <div className="modal" id={modalId} tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{modalTitle}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            onClick={(e) => {formInfo.formCloseAction.action(e.currentTarget.value)}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <BasicForm {...formInfo} />
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </div>
    </div>
}