import * as React from "react";
import Navbar from "../views/navbar";
import {useIsAuthenticated} from "@azure/msal-react";
import {useState} from "react";
import Person from "../models/profile/person";
import {useEffect} from "react";
import Sidebar from "../views/sidebar";
import NavigationControllerInfo from "../interfaces/props/controllers/navigationControllerInfo";

function NavigationController(props: NavigationControllerInfo){


    const {
        navLinks,
        viewModel
    } = props;

    const isAuthenticated = useIsAuthenticated();

    const [person, setPerson] = useState<Person>(new Person("Not Signed In", "Not Signed In"));

    useEffect(() => {
        if (isAuthenticated) {
            viewModel.getMyProfile().then(person => {
                setPerson(person);
            });
        }
    }, [viewModel, isAuthenticated]);


    return <div>
        <Sidebar
            person={person}/>
        <Navbar
            navLinks={navLinks}/>
        </div>
}

export default NavigationController;