import React  from "react";
import {Image, StyleSheet, View} from '@react-pdf/renderer';
import ombaCompanyName from "../../../images/OmbaCompanyName.png";


function Header(){

    // Create styles
    const styles = StyleSheet.create({
        column: {
            flexDirection: "column",
            display: "flex",
            flexWrap: "nowrap",
            flexShrink: 0,
            alignSelf: 'flex-end'
        },
        companyName: {
            flexBasis: "5%",
            alignSelf: 'flex-end'
        }}
    );

    return <View style={styles.column} fixed>
        <Image style={styles.companyName} src={ombaCompanyName}/>
    </View>
}

export default Header;