import {useEffect, useState} from 'react';
import Dashboard from "../views/dashboard";
import Column from "../interfaces/column";
import SmallBoxInfo from "../interfaces/props/views/smallBoxInfo";
import Variant from "../interfaces/enums/variant";
import BasicFormInfo from "../interfaces/props/views/basicFormInfo";
import FormField from "../interfaces/formField";
import FormModalInfo from "../interfaces/props/views/formModalInfo";
import Identifier from "../models/instruments/identifier";
import * as React from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EtfPosition from "../models/etfPositions/etfPosition";
import EtfPositionsDashboardControllerInfo from "../interfaces/props/controllers/etfPositionsDashboardControllerInfo";
import { ExportToCsv } from 'export-to-csv';
import DataTableInfo from "../interfaces/props/views/dataTableInfo";

const MySwal = withReactContent(Swal);

function EtfPositioinsDashboardController(props: EtfPositionsDashboardControllerInfo){

    const [identifiers, setIdentifiers] = useState<Identifier[]>([]);
    const [etfPositions, setEtfPositions] = useState<EtfPosition[]>([]);

    // The field to use to get ETF Positions
    const defaultGetEtfPositionsFormFields : {[id: string]: FormField} = {
        // Text input field for the representative date
        "representativeDate": new FormField(
            "Representative Date",
            "representativeDate",
            "representativeDate",
            "string",
            "Default",
            false,
            "",
            "2020-01-01"
        ),
        // Selectable drop down for the identifier type
        "identifierType": new FormField(
            "Identifier Type",
            "identifierType",
            "identifierType",
            "string",
            "Default",
            true,
            "",
            "KeyRIC",
            identifiers.map(identifier => identifier.name),
            false,
            "identifierType-select",
            "identifierType-select",
            "KeyRIC",
            "KeyRIC"
        ),
        "lookThrough": new FormField(
            "Lookthrough?",
            "lookThrough",
            "lookThrough",
            "checkbox",
            "Default",
            false,
            "checked=off",
        ),
        "etf0": new FormField(
            "ETF",
            "etf0",
            "etf0",
            "string",
            "ETFs",
            false,
            "",
            "SSAC.L"
        )
    };

    const [getEtfPositionFormFields, setGetEtfPositionFormFields] = useState<{[id: string]: FormField}>(defaultGetEtfPositionsFormFields);

    // Runs every time any of the dependencies (deps) change
    useEffect(() => {
        props.instrumentViewModel.listIdentifiers().then(ids => setIdentifiers(ids));
    }, [props.instrumentViewModel, props.etfPositionViewModel]);

    useEffect(() => {
        setGetEtfPositionFormFields(defaultGetEtfPositionsFormFields)
    }, [identifiers]);

    // Used to construct an identifier values
    const constructIdentifiers = (identifiers: {[id: string]: FormField}, prefix: string): string[] => {

        let identifierValues: string[] = [];

        for (const [id, formField] of Object.entries(identifiers)) {
            if (id.includes(prefix)){
                identifierValues.push(formField.value)
            }
        }

        return identifierValues;
    };


    // These are the columns known in advance to use in the datatable to show etf positions
    const columns:Column[]  = [
            {
                label: 'ETF Id',
                field: 'ETFId',
                width: 10
            },
            {
                label: 'Provided Etf Id',
                field: 'ProvidedETFId',
                width: 10
            },
            {
                label: 'ETF Identifier Type',
                field: 'ETFIdentifierType',
                width: 10
            },
            {
                label: 'ETF Identifier Value',
                field: 'ETFIdentifierValue',
                width: 10
            },
            {
                label: 'ETF Name',
                field: 'ETFName',
                width: 10
            },
            {
                label: 'ETF Description',
                field: 'ETFDescription',
                width: 10
            },
            {
                label: 'ETF Currency',
                field: 'ETFCurrency',
                width: 10
            },
            {
                label: 'ETF Type',
                field: 'ETFType',
                width: 10
            },
            {
                label: 'Representative Date',
                field: 'RepresentativeDate',
                width: 10
            },
            {
                label: 'Holdings Date',
                field: 'HoldingsDate',
                width: 10
            },
            {
                label: 'Underlying Instrument Id',
                field: 'UnderlyingInstrumentId',
                width: 10
            },
            {
                label: 'Underlying Instrument Identifier Type',
                field: 'UnderlyingInstrumentIdentifierType',
                width: 10
            },
            {
                label: 'Underlying Instrument Identifier Value',
                field: 'UnderlyingInstrumentIdentifierValue',
                width: 10
            },
            {
                label: 'Underlying Instrument Name',
                field: 'UnderlyingInstrumentName',
                width: 10
            },
            {
                label: 'Underlying Instrument Description',
                field: 'UnderlyingInstrumentDescription',
                width: 10
            },
            {
                label: 'Underlying Instrument Currency',
                field: 'UnderlyingInstrumentCurrency',
                width: 10
            },
            {
                label: 'Underlying Instrument Type',
                field: 'UnderlyingInstrumentType',
                width: 10
            },
            {
                label: 'Underlying Instrument Status',
                field: 'UnderlyingInstrumentStatus',
                width: 10
            },
            {
                label: 'Weight (%)',
                field: 'Weight',
                width: 10
            },
            {
                label: 'Quantity',
                field: 'Quantity',
                width: 10
            },
            {
                label: 'Lineage',
                field: 'Lineage',
                width: 20
            }
        ];

    const displayBoxes:SmallBoxInfo[] = [
        {
            value: constructIdentifiers(getEtfPositionFormFields, "etf").length.toString(),
            label: "Number of ETFs",
            type: constructIdentifiers(getEtfPositionFormFields, "etf").length > 0 ? Variant.Success : Variant.Danger
        },
        {
            value: etfPositions.length.toString(),
            label: "Total Number of Positions",
            type: etfPositions.length > 0 ? Variant.Info : Variant.Danger
        }
    ];

    // This is invoked when a user wants to add another identifier when creating an instrument
    const addEtfToForm = (e:Event) => {

        const numberFields = Object.keys(getEtfPositionFormFields).filter(key => key.includes("etf")).length.toString();
        const etfFieldName = "etf" + numberFields;

        // Add new selectable drop down for the identifier type and a text input for the identifier value
        setGetEtfPositionFormFields({
                ...getEtfPositionFormFields,
                [etfFieldName]:  new FormField(
                "ETF",
                    etfFieldName,
                    etfFieldName,
                "string",
                "ETFs",
                false,
                "",
                "SSAC.L"
            )
        });
    };

    // Ensures that when the form is updated the new state of the form is saved
    const updateEtfFormData = (e:React.FormEvent<HTMLInputElement>) => {

        let formFieldId = e.currentTarget.id.split("-")[0];
        let formField = getEtfPositionFormFields[formFieldId];

        if (e.currentTarget.id.includes("-select")) {
            formField.selectValue = e.currentTarget.value;
        }

        else if (e.currentTarget.type == "checkbox"){
            formField.checked = !formField.checked
        }

        else {
            formField.value = e.currentTarget.value;
        }

        setGetEtfPositionFormFields(prevState => (
            {
                ...prevState,
                [formFieldId]: formField
            }));
    };

    // Handles getting ETF data
    const getEtfData = (e:React.FormEvent<HTMLInputElement>) => {
        e.preventDefault(); // This ensures that the form submit does not cause a HTTP request in the browser
        MySwal.fire({
            title: 'Fetching ETF Positions... please wait',
            icon: "info",
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            didOpen: () => {
                Swal.showLoading();
                props.etfPositionViewModel.getEtfPositions(
                    getEtfPositionFormFields["representativeDate"].value,
                    getEtfPositionFormFields["identifierType"].selectValue,
                    constructIdentifiers(getEtfPositionFormFields, "etf"),
                    getEtfPositionFormFields["lookThrough"].checked ? "5" : "0"
                ).then((etfPositions) => {
                    setEtfPositions(etfPositions)
                }).then(() =>
                    MySwal.fire({
                        title: 'ETF Positions Retrieved!',
                        icon: "success",
                        position: 'top-end',
                        toast: true
                    })
                ).catch((error) => MySwal.fire({
                    title: 'Failure!',
                    text: error,
                    icon: "error",
                    position: 'top-end',
                    toast: true
                }));
            }
        });

    };

    // Handles closing the form by resetting the values
    const closeGetEtfPositionsForm = (e:React.FormEvent<HTMLInputElement>) => {
        setGetEtfPositionFormFields(defaultGetEtfPositionsFormFields);
    };

    const getEtfPositionsFormInfo: BasicFormInfo = {
        "formSubmitAction": {
            "action": getEtfData
        },
        "formOnChangeAction": {
            "action": updateEtfFormData
        },
        "formSubmitLabel": "Get Positions",
        "formAddLabel": "Add ETF",
        "formFields": Object.values(getEtfPositionFormFields),
        "formOnAddAction": {
            "action": addEtfToForm
        },
        "formCloseAction": {
            "action": closeGetEtfPositionsForm
        }
    };

    const etfSelectorFormModalInfo: FormModalInfo = {
        "modalId": "ETFSelector",
        "modalTitle": "Select ETFs",
        "formInfo": getEtfPositionsFormInfo
    };

    // These are arbitrary columns to use depending on what identifiers exist, for example if ISIN, CUSIP and RIC
    // are the available identifiers there will be an ISIN, CUSIP and RIC column
    const idColumns = (etfPositions: EtfPosition[]) => {
        if (etfPositions.length == 0){
            return []
        }

        var etfIds: Column[] = [];
        var underlyingInstrumentIds: Column[] = [];

        Object.entries(etfPositions[0].etfIdentifiers).forEach(
            ([key, value]) => etfIds.push(
                {
                    label: "ETFInstrumentIdentifier." + key,
                    field: "ETFInstrumentIdentifier." + key,
                    width: 10
                }
            )
        );

        Object.entries(etfPositions[0].underlyingInstrumentIdentifiers).forEach(
            ([key, value]) => underlyingInstrumentIds.push(
                {
                    label: "UnderlyingInstrumentIdentifier." + key,
                    field: "UnderlyingInstrumentIdentifier." + key,
                    width: 10
                }
            )
        );

        return etfIds.concat(underlyingInstrumentIds);
    };

    // These are arbitrary columns to use depending on what identifiers exist, for example if ISIN, CUSIP and RIC
    // are the available identifiers there will be an ISIN, CUSIP and RIC column
    const idValues = (etfPosition: EtfPosition) => {

        var identifierValues: { [identifierType: string] : string; } = {};

        Object.entries(etfPosition.etfIdentifiers).forEach(
            ([key, value]) => identifierValues["ETFInstrumentIdentifier." + key] = value
        );

        Object.entries(etfPosition.underlyingInstrumentIdentifiers).forEach(
            ([key, value]) => identifierValues["UnderlyingInstrumentIdentifier." + key] = value
        );

        return identifierValues;
    };

    // Creates the datatable to display
    const datatable ={
        columns: columns.concat(idColumns(etfPositions)), // Known plus ID columns
        rows: etfPositions.map(etfPosition => {
            let baseProps = {
                "ETFId": etfPosition.etfId,
                "ProvidedETFId": etfPosition.providedEtfId,
                "ETFIdentifierType": etfPosition.etfIdentifierType,
                "ETFIdentifierValue": etfPosition.etfIdentifierValue,
                "ETFName": etfPosition.etfName,
                "ETFDescription": etfPosition.etfDescription,
                "ETFCurrency": etfPosition.etfCurrency,
                "ETFType": etfPosition.etfType,
                "RepresentativeDate": etfPosition.representativeDate,
                "HoldingsDate": etfPosition.holdingsDate,
                "UnderlyingInstrumentId": etfPosition.underlyingInstrumentId,
                "UnderlyingInstrumentIdentifierType": etfPosition.instrumentIdentifierType,
                "UnderlyingInstrumentIdentifierValue": etfPosition.instrumentIdentifierValue,
                "UnderlyingInstrumentName": etfPosition.underlyingInstrumentName,
                "UnderlyingInstrumentDescription": etfPosition.underlyingInstrumentDescription,
                "UnderlyingInstrumentCurrency": etfPosition.underlyingInstrumentCurrency,
                "UnderlyingInstrumentType": etfPosition.underlyingInstrumentType,
                "UnderlyingInstrumentStatus": etfPosition.underlyingInstrumentStatus,
                "Weight": etfPosition.weight,
                "Quantity": etfPosition.quantity,
                "Lineage": etfPosition.lineage
            };

            let identifiers = idValues(etfPosition);

            return {
                ...baseProps,
                ...identifiers
            }
            })
        };

    const Export = () => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            title: 'EtfPositions',
            useKeysAsHeaders: true,
        };

        const csvExporter = new ExportToCsv(options);

        if (datatable.rows.length == 0){
            MySwal.fire({
                title: 'No data to export!',
                position: 'top-end',
                icon: 'error',
                toast: true
            });
            return
        }

        csvExporter.generateCsv(datatable.rows);
    };

    const exportFunction = {"action": Export};

    const dataTableInfo: DataTableInfo = {
        "data": datatable,
        "entries": 1000
    }

    return(
        <Dashboard
            clickLabel={"Select ETFs"}
            newEntityFormModalInfo={etfSelectorFormModalInfo}
            editEntityFormModalInfo={etfSelectorFormModalInfo}
            datatableInfo={dataTableInfo}
            displayBoxes={displayBoxes}
            exportFunction={exportFunction}/>
    )

}

export default EtfPositioinsDashboardController;