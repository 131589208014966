import ChartingModule from "../interfaces/modules/chartingModule";
import Plotly from "plotly.js";
import ChartData from "../interfaces/chartData";

class ClientChartingModule implements ChartingModule{

    constructor(){
    }

    render(
        chartData: ChartData
    ): Promise<string>{
        return Plotly.toImage({
            data: chartData.data,
            layout: chartData.layout
        }, chartData.image)
    }
}

export default ClientChartingModule;