import * as React from "react";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {loginRequestScopes} from "../authConfig";
import {Redirect} from "react-router";
import SignInPage from "../views/signInPage";

function SignInPageController(){

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = (loginType: string) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequestScopes)
                .then(() => {
                    return <Redirect to='/dashboard' />
                })
                .catch(e => {
                    console.log(e);
                });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequestScopes).catch(e => {
                console.log(e);
            });
        }
    };

    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    };

    const signInOutAction = {"action": isAuthenticated ? handleLogout : handleLogin};

    return(
        <SignInPage signInOutAction={signInOutAction} action={isAuthenticated ? "Sign out" : "Sign in"}/>
    )

}

export default SignInPageController;