class EtfPosition{

    etfIdentifiers: { [identifierType: string] : string; };

    underlyingInstrumentIdentifiers: { [identifierType: string] : string; };

    representativeDate: string;

    providedEtfId: string;

    etfId?: string;

    etfIdentifierType?: string;

    etfIdentifierValue?: string;

    etfName?: string;

    etfDescription?: string;

    etfCurrency?: string;

    etfType?: string;

    holdingsDate?: string;

    underlyingInstrumentId?: string;

    instrumentIdentifierType?: string;

    instrumentIdentifierValue?: string;

    underlyingInstrumentName?: string;

    underlyingInstrumentDescription?: string;

    underlyingInstrumentCurrency?: string;

    underlyingInstrumentType?: string;

    underlyingInstrumentStatus?: string;

    weight?: number;

    quantity?: number;

    lineage?: string;


constructor(
    representativeDate: string,
    etfIdentifiers: { [identifierType: string] : string; },
    underlyingInstrumentIdentifiers: { [identifierType: string] : string; },
    providedEtfId: string,
    etfId?: string,
    etfIdentifierType?: string,
    etfIdentifierValue?: string,
    etfName?: string,
    etfDescription?: string,
    etfCurrency?: string,
    etfType?: string,
    holdingsDate?: string,
    underlyingInstrumentId?: string,
    instrumentIdentifierType?: string,
    instrumentIdentifierValue?: string,
    underlyingInstrumentName?: string,
    underlyingInstrumentDescription?: string,
    underlyingInstrumentCurrency?: string,
    underlyingInstrumentType?: string,
    underlyingInstrumentStatus?: string,
    weight?: number,
    quantity?: number,
    lineage?: string
    ){
        this.etfId = etfId;
        this.etfIdentifierType = etfIdentifierType;
        this.etfIdentifierValue = etfIdentifierValue;
        this.providedEtfId = providedEtfId;
        this.etfName = etfName;
        this.etfDescription = etfDescription;
        this.etfCurrency = etfCurrency;
        this.etfType = etfType;
        this.representativeDate = representativeDate;
        this.holdingsDate = holdingsDate;
        this.underlyingInstrumentId = underlyingInstrumentId;
        this.instrumentIdentifierType = instrumentIdentifierType;
        this.instrumentIdentifierValue = instrumentIdentifierValue;
        this.underlyingInstrumentName = underlyingInstrumentName;
        this.underlyingInstrumentDescription = underlyingInstrumentDescription;
        this.underlyingInstrumentCurrency = underlyingInstrumentCurrency;
        this.underlyingInstrumentType = underlyingInstrumentType;
        this.underlyingInstrumentStatus = underlyingInstrumentStatus;
        this.weight = Number(weight);
        this.quantity = Number(quantity);
        this.lineage = lineage;
        this.etfIdentifiers = etfIdentifiers;
        this.underlyingInstrumentIdentifiers = underlyingInstrumentIdentifiers;
    }
}

export default EtfPosition;