import {Layout, PlotType} from "plotly.js";
import BranchValues from "./types";
import chartColours from "./chartColours";
import ChartData from "../interfaces/chartData";

export default function ombaSunburst(
    parents: string[],
    labels: string[],
    values: number[],
    ids: string[],
    layout: Partial<Layout>,
    text?: string[],
    aspectRatio: number=1
): ChartData{

    const height = 600;

    return {
        data: [{
            parents: parents,
            labels: labels,
            values: values,
            ids: ids,
            text: text,
            type: 'sunburst' as PlotType,
            branchvalues: 'total' as BranchValues,
        }],
        layout: {
            ...layout,
            colorway: Object.keys(chartColours),
            margin: {t:0, l:0, r:0, b:0}
        },
        image: {format: "png", width: height * aspectRatio, height: height, scale: 2}
    };
}