export default class FormField{

    label: string;

    name: string;

    type: string;

    id: string;

    fieldSet: string;

    placeholder?: string;

    includeSelect: boolean;

    value: string;

    selectOptions?: string[];

    includeTextInput?: boolean;

    selectName?: string;

    selectId?: string;

    selectPlaceholder?: string;

    selectValue: string;

    textReadOnly: boolean;

    selectReadOnly: boolean;

    checked: boolean;

    constructor(
        label: string,
        name: string,
        id: string,
        type: string,
        fieldSet: string,
        includeSelect: boolean,
        value: string,
        placeholder?: string,
        selectOptions?: string[],
        includeTextInput: boolean = true,
        selectName?: string,
        selectId?: string,
        selectPlaceholder?: string,
        selectValue: string = "",
        textReadOnly: boolean = false,
        selectReadOnly: boolean = false,
        checked: boolean = false
    ){
        this.label = label;
        this.name = name;
        this.id = id;
        this.type = type;
        this.fieldSet = fieldSet;
        this.placeholder = placeholder;
        this.includeSelect = includeSelect;
        this.value = value;
        this.selectOptions = selectOptions;
        this.includeTextInput = includeTextInput;
        this.selectName = selectName === undefined ? name : selectName;
        this.selectId = selectId;
        this.selectPlaceholder = selectPlaceholder;
        this.selectValue = selectValue;
        this.textReadOnly = textReadOnly;
        this.selectReadOnly = selectReadOnly;
        this.checked = checked;
    }
};