import React from 'react';
import Content from "./adminLTE/content";
import DashboardInfo from "../interfaces/props/views/dashboardInfo";
import {setAttributes} from "../../public/plugins/popper/popper-utils";

function Dashboard(props:DashboardInfo){

    const {
        clickLabel,
        newEntityFormModalInfo,
        editEntityFormModalInfo,
        datatableInfo,
        displayBoxes,
        exportFunction,
        searchFunction
    } = props;


    return (
        <React.Fragment>
            <Content
                clickLabel={clickLabel}
                newEntityFormModalInfo={newEntityFormModalInfo}
                editEntityFormModalInfo={editEntityFormModalInfo}
                datatableInfo={datatableInfo}
                displayBoxes={displayBoxes}
                exportFunction={exportFunction}
                searchFunction={searchFunction}
            />
        </React.Fragment>
    )

}

export default Dashboard;