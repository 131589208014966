import InstrumentViewModel from "../viewmodel/instrumentViewModel";
import * as React from "react";
import OmbaInstrumentStore from "../models/instruments/stores/ombaInstrumentStore";
import {useMsal} from "@azure/msal-react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import SignInPageController from "../controllers/signInPageController";
import ProfileViewModel from "../viewmodel/profileViewModel";
import MicrosoftGraphProfileStore from "../models/profile/stores/microsoftGraphProfileStore";
import ProtectedRoute from "../routes/protectedRoute";
import InstrumentDashboardController from "../controllers/instrumentDashboardController";
import NavigationController from "../controllers/navigationController";
import EtfPositioinsDashboardController from "../controllers/etfPositionsDashboardController";
import EtfPositionsViewModel from "../viewmodel/etfPositionsViewModel";
import OmbaEtfPositionsStore from "../models/etfPositions/stores/ombaEtfPositionsStore";
import ReportGeneratorController from "../controllers/reportGeneratorController";
import ClientPositionsViewModel from "../viewmodel/clientPositionsViewModel";
import CsvFileClientPositionStore from "../models/clientPositions/stores/csvFileClientPositionStore";
import OmbaInstrumentStoreWithGeneratedEnrichment
    from "../models/instruments/stores/ombaInstrumentStoreWithGeneratedEnrichment";
import EikonReferenceDataStore from "../models/referenceData/stores/eikonReferenceDataStore";
import OmbaInstrumentStoreWithEikonEnrichment
    from "../models/instruments/stores/ombaInstrumentStoreWithEikonEnrichment";
import OmbaPmsClientPositionStore from "../models/clientPositions/stores/ombaPmsClientPositionStore";
import ClientPositionsDashboardController from "../controllers/clientPositionsDashboardController";
import PortfolioAnalysisReportDataViewModel from "../viewmodel/portfolioAnalysisReportDataViewModel";
import ClientChartingModule from '../charting/clientChartingModule';

import LocalEtfPositionsStore from "..//models/etfPositions/stores/localEtfPositionsStore";
import LocalClientPositionStore from "../models/clientPositions/stores/localClientPositionStore";
import LocalInstrumentStoreWithGeneratedEnrichment
    from "../models/instruments/stores/localInstrumentStoreWithGeneratedEnrichment";
import clientPositions from "../test/sampleData/clientPositions.json";
import instruments from "../test/sampleData/instruments.json";
import etfPositions from "../test/sampleData/etfPositions.json";

function Provider(){

    const { instance, accounts } = useMsal();

    const csvFilePositionStore = new CsvFileClientPositionStore("Client Holdings File: ");
    const clientChartingModule = new ClientChartingModule();

    const ombaApiUrl = process.env.REACT_APP_BASE_OMBA_API_URI || "";
    const eikonApiUrl = process.env.REACT_APP_BASE_EIKON_API_URI || "";

    const ombaPmsClientPositionStore = new OmbaPmsClientPositionStore(ombaApiUrl, accounts[0], instance);
    const ombaEtfPositionsStore = new OmbaEtfPositionsStore(ombaApiUrl, accounts[0], instance)
    const eikonReferenceDataStore = new EikonReferenceDataStore(eikonApiUrl);
    const ombaInstrumentStoreWithEikonEnrichment = new OmbaInstrumentStoreWithEikonEnrichment(ombaApiUrl, accounts[0], instance, eikonReferenceDataStore)
    const ombaInstrumentStore = new OmbaInstrumentStore(ombaApiUrl, accounts[0], instance)

    const instrumentViewModel = new InstrumentViewModel(ombaInstrumentStore);
    const reportInstrumentViewModel = new InstrumentViewModel(ombaInstrumentStoreWithEikonEnrichment);
    const etfPositionsViewModel = new EtfPositionsViewModel(ombaEtfPositionsStore);
    const profileViewModel = new ProfileViewModel(new MicrosoftGraphProfileStore("https://graph.microsoft.com/v1.0", accounts[0], instance));
    const clientPositionsViewModel = new ClientPositionsViewModel(ombaPmsClientPositionStore);
    const portfolioAnalysisReportDataViewModel = new PortfolioAnalysisReportDataViewModel(
        reportInstrumentViewModel,
        clientPositionsViewModel,
        etfPositionsViewModel,
        clientChartingModule
    )

    const navLinks = [
        {
            "linkAddress": "/",
            "label": "Home"
        },
        {
            "linkAddress": "/login",
            "label": "Login"
        },
        {
            "linkAddress": "/instruments",
            "label": "Instruments Dashboard"
        },
        {
            "linkAddress": "/etf_positions",
            "label": "ETF Positions Dashboard"
        },
        {
            "linkAddress": "/client_positions",
            "label": "Client Positions Dashboard"
        },
        {
            "linkAddress": "/reports",
            "label": "Reporting"
        },
    ];

    return (
        <div>
            <NavigationController navLinks={navLinks} viewModel={profileViewModel}/>
            <BrowserRouter>
                <Switch>
                    <Route path="/login">
                        <SignInPageController/>
                    </Route>
                    <ProtectedRoute path="/instruments">
                        <InstrumentDashboardController viewModel={instrumentViewModel}/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/etf_positions">
                        <EtfPositioinsDashboardController
                            instrumentViewModel={instrumentViewModel}
                            etfPositionViewModel={etfPositionsViewModel}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute path="/client_positions">
                        <ClientPositionsDashboardController
                            clientPositionViewModel={clientPositionsViewModel}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute path="/reports">
                        <ReportGeneratorController
                            clientPositionsViewModel={clientPositionsViewModel}
                            etfPositionsViewModel={etfPositionsViewModel}
                            instrumentViewModel={reportInstrumentViewModel}
                            clientPositionsCsvReader={csvFilePositionStore.csvReader}
                            portfolioAnalysisReportDataViewModel={portfolioAnalysisReportDataViewModel}
                        />
                    </ProtectedRoute>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default Provider;