import Person from "../person";
import ProfileStore from "./profileStore";
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {loginRequestScopes, ombaApiScopes} from "../../../authConfig";
import {User} from "@microsoft/microsoft-graph-types";
import {callMsGraph} from "../../../graph";

/*
Represents a store of Profile data
 */
class MicrosoftGraphProfileStore implements ProfileStore{

    baseApiUrl: string;

    account: AccountInfo;

    instance: IPublicClientApplication;

    constructor(baseApiUrl: string, account: AccountInfo, instance: IPublicClientApplication){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
    }

    getAccessToken(): Promise<string>{

        return this.instance.acquireTokenSilent({
            ...loginRequestScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    getMyProfile(): Promise<Person>{

        return this.getAccessToken().then((accessToken) => {
            return callMsGraph(accessToken).then(response => {
                return response as User}).then((user) => {
                    var name = user.displayName == null ? "unknown" : user.displayName;
                    var email = user.mail == null ? "unknown" : user.mail;

                    return new Person( name, email);
                });
        })
    }
}

export default MicrosoftGraphProfileStore;