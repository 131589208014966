import React from "react";
import SmallBoxInfo from "../../../interfaces/props/views/smallBoxInfo";

function SmallBox(props: SmallBoxInfo){

    const {
        type,
        value,
        label,
        moreInfoLink
    } = props;

    return <div className={"small-box bg-" + type}>
        <div className="inner">
            <h3>{value}</h3>
            <p>{label}</p>
        </div>
        <div className="icon">
            <i className="ion ion-bag" />
        </div>
        <a href={"#" + moreInfoLink} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
    </div>
}

export default SmallBox;