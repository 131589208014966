import ClientPositionStore from "../models/clientPositions/stores/clientPositionStore";
import ClientPosition from "../models/clientPositions/clientPosition";
import ClientPositionWithLookThrough from "../models/clientPositions/clientPositionWithLookThrough";


class ClientPositionsViewModel {

    store: ClientPositionStore;

    constructor(clientPositionsStore: ClientPositionStore) {
        this.store = clientPositionsStore
    }

    getClientPositions(
        representativeDate: string,
        accountNumbers: string[]
    ): Promise<ClientPosition[]>{
        return this.store.getClientPositions(representativeDate, accountNumbers);
    }

    getClientPositionsWithLookThrough(
        representativeDate: string,
        accountNumbers: string[],
        lookThroughDepth?: string,
        aggregateMethod?: string
    ): Promise<ClientPositionWithLookThrough[]>{
        return this.store.getClientPositionsWithLookThrough(representativeDate, accountNumbers, lookThroughDepth, aggregateMethod);
    }
}

export default ClientPositionsViewModel