import React from 'react';
import './App.css';
import Provider from "./provider/provider";

function App() {

  return (
    <div className="wrapper">
        <Provider/>
    </div>
  );
}

export default App;
