const formatDate = (date: Date, includeDay: boolean=true, includeYear: boolean=true, shortMonth=false) => {

    let months = [
        "January",
        "Februray",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear();

    if (shortMonth){
        month = month.substring(0,3);
    }

    if ((includeDay) && (includeYear)){
        return `${day} ${month} ${year}`;
    }

    if (includeDay){
        return `${day} ${month}`;
    }

    if (includeYear){
        return `${month} ${year}`;
    }

    return month;
    
};

export default formatDate;