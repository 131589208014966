import BasicFormInfo from "../../../interfaces/props/views/basicFormInfo";
import FormField from "../../../interfaces/formField";

function BasicForm(props: BasicFormInfo) {

    const {
        formSubmitAction,
        formOnChangeAction,
        formSubmitLabel,
        formAddLabel,
        formFields,
        formOnAddAction
    } = props;

    function renderFormFields(formFields: FormField[]){


        let fieldSets = formFields.reduce<Record<string, FormField[]>>((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            result[currentValue.fieldSet] = [...result[currentValue.fieldSet] || [], currentValue];

            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;

        }, {}); // empty object is the initial value for result object

        return Object.keys(fieldSets).map(fieldSetName => {

            return <div className="row">
                <div className="col-md-12">
                    <div className={`card ${(fieldSetName.toLowerCase().includes("warning")) ? "card-red" : "card-green"}`}>
                        <div className="card-header">
                            <h3 className="card-title">{fieldSetName}</h3>

                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand"></i>
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                    </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <fieldset>
                                {fieldSets[fieldSetName].map(field => {
                                    return <div className="form-group">
                                        <label htmlFor={field.label}>{field.label}</label>
                                        {
                                            field.includeSelect ? (
                                                <select
                                                    className="form-control select2"
                                                    onChange={formOnChangeAction.action}
                                                    value={field.selectValue}
                                                    name={field.selectName}
                                                    id={field.selectId}
                                                    disabled={field.selectReadOnly}
                                                >
                                                    {field?.selectOptions?.map(option => <option value={option} key={option}>{option}</option>)}
                                                </select>
                                            ) : null
                                        }
                                        {
                                            field.includeTextInput ? (
                                                <input
                                                    type={field.type}
                                                    className="form-control"
                                                    id={field.id}
                                                    placeholder={field.placeholder}
                                                    value={field.value}
                                                    name={field.name}
                                                    readOnly={field.textReadOnly}
                                                    onChange={formOnChangeAction.action}
                                                    checked={field.checked}/>
                                            ) : null
                                        }
                                    </div>
                                })
                                }
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    return <div>
    <form onSubmit={formSubmitAction.action}>
        <div className="card-body">
            {renderFormFields(formFields)}
            <button type="button" className="btn btn-warning" onClick={formOnAddAction?.action}>{formAddLabel}</button>
        </div>
        {/* /.card-body */}
        <div className="card-footer">
            <button type="submit" className="btn btn-primary">{formSubmitLabel}</button>
        </div>
    </form>
    </div>
}

export default BasicForm;