import Instrument from "../instrument";
import InstrumentType from "../../../interfaces/enums/instrumentType";
import Identifier from "../identifier";
import OmbaIdentifier from "../../../interfaces/ombaApi/ombaIdentifier";
import InstrumentStore from "./instrumentStore";
import OmbaInstrument from "../../../interfaces/ombaApi/ombaInstrument";
import OmbaApiError from "../../../interfaces/ombaApi/ombaApiError";
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import Attributes, {EikonInstrumentAttributes} from "../../../interfaces/enums/attributes";
import Attribute from "../attribute";
import OmbaAttribute from "../../../interfaces/ombaApi/ombaAttribute";
import SummaryStatistic from "../summaryStatistic";
import OmbaSummaryStatistic from "../../../interfaces/ombaApi/ombaSummaryStatistic";

/*
The OMBA Instrument Store which stores the Instruments in the OMBA Instrument Store via the OMBA APIs
 */
class OmbaInstrumentStore implements InstrumentStore {

    baseApiUrl: string;

    account?: AccountInfo;

    instance?: IPublicClientApplication;

    private accessToken?: string;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
    }

    setAccessToken(accessToken: string): void{
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    getSummary(summaryField: string): Promise<SummaryStatistic[]> {
        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/instruments/summary?summaryField=${summaryField}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => response.json())
                .then(res => {
                    return res as OmbaSummaryStatistic[]
                })
                .then(summaryStatistics => summaryStatistics.map(summaryStatistic => {
                    return new SummaryStatistic(
                        summaryStatistic.Group,
                        summaryStatistic.Count)
                }));
        }
    )}

    getAttributes(source: string): Promise<Attribute[]> {

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/instruments/attributes/${source}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => response.json())
                .then(res => {
                    return res as OmbaAttribute[]
                })
                .then(attributes => attributes.map(attribute => {
                    return new Attribute(
                        attribute.Name,
                        attribute.Source,
                        attribute.DataType,
                        attribute.Description)
                }));
        })
    }

    getInstrument(instrumentId: string, attributes: string[]): Promise<Instrument> {

        let params = new URLSearchParams();

        attributes.map((attribute) => params.append("attribute", attribute));

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/instruments/${instrumentId}?` + params, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => response.json())
                .then(res => {
                    return res as OmbaInstrument
                })
                .then(instrument => {
                    return new Instrument(
                        instrument.Name,
                        instrument.Type as InstrumentType,
                        instrument.Currency,
                        instrument.Identifiers,
                        instrument.Attributes,
                        instrument.Status,
                        instrument.Description,
                        instrument.InstrumentId)
                });
        })
    }

    getInstruments(instrumentIds: string[], attributes: string[]): Promise<Instrument[]> {

        let instrumentResponses = [];

        const instrumentsPerRequest = 100;

        for (let i = 0; i < instrumentIds.length; i += instrumentsPerRequest) {

            const chunk = instrumentIds.slice(i, i + instrumentsPerRequest);

            let params_chunk = new URLSearchParams();

            // Eikon attributes don't exist in the Omba Instrument Store and can only be sourced from Eikon so need to pass along
            attributes.filter(attribute => !Object.values(Attributes.Instrument.Eikon).includes(attribute as EikonInstrumentAttributes)).map((attribute) => params_chunk.append("attribute", attribute));
            chunk.map((instrumentId) => params_chunk.append("id", instrumentId));

            instrumentResponses.push(this.getAccessToken().then((accessToken) => {
                return fetch(`${this.baseApiUrl}/instruments/?` + params_chunk, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                        },
                    })
                    .then(response => response.json())
                    .then(res => {
                        return res as OmbaInstrument[]
                    })
            }))
        }

        return Promise.all(instrumentResponses).then((values) => {
            return values.flat().map(instrument => {
                return new Instrument(
                    instrument.Name,
                    instrument.Type as InstrumentType,
                    instrument.Currency,
                    instrument.Identifiers,
                    instrument.Attributes,
                    instrument.Status,
                    instrument.Description,
                    instrument.InstrumentId)
            });
        })
    }

    listInstruments(limit?: number, offset?: number, attributes?: string[], search?: string): Promise<Instrument[]>{

        let params = new URLSearchParams();

        if (limit != null){
            params.append("limit", limit.toString())
        }

        if (offset != null){
            params.append("offset", offset.toString())
        }

        if (attributes != null){
            attributes.filter(attribute => !Object.values(Attributes.Instrument.Eikon).includes(attribute as EikonInstrumentAttributes)).map((attribute) => params.append("attribute", attribute));
        }

        if (search != null){
            params.append("search", search)
        }

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/instruments/?` + params, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => response.json())
                .then(res => {
                    return res as OmbaInstrument[]
                })
                .then(instruments => instruments.map(instrument => {
                    return new Instrument(
                        instrument.Name,
                        instrument.Type as InstrumentType,
                        instrument.Currency,
                        instrument.Identifiers,
                        instrument.Attributes,
                        instrument.Status,
                        instrument.Description,
                        instrument.InstrumentId)
                }));
        })
    }

    upsertInstruments(instruments: Instrument[]): Promise<Instrument[]> {

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/instruments/`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(instruments)
            })
                .then(response => {
                    if (!response.ok){
                        return response.json()
                            .then((res: OmbaApiError) => {
                                throw Error(res.error)
                            })
                    }
                    return response.json()
                })
                .then(() => {
                    return instruments
                })
        })
    };

    deleteInstrument(instrumentId: string): Promise<string> {

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/instruments/${instrumentId}`, {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    if (response.ok){
                        return response.json()
                    }
                    return response.text().then(text => {throw new Error(response.statusText + ": " + text)});
                })
                .then(() => {
                    return instrumentId
                })
        });
    }

    listIdentifiers(): Promise<Identifier[]>{

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/identifiers/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(response => response.json())
                .then(res => {
                    return res as OmbaIdentifier[]
                })
                .then(identifiers => identifiers.map(identifier => {
                    return new Identifier(identifier.name, identifier.is_unique)
                }))});
    }

}

export default OmbaInstrumentStore