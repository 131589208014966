import React  from "react";
import {Image, StyleSheet, View, Text} from '@react-pdf/renderer';
import ombaLogo from "../../../images/ombaLogoWhiteBg.png";


function Footer(){

    // Create styles
    const styles = StyleSheet.create({
        footer: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: 45,
            flexShrink: 0,
            position: "absolute",
            left: 20,
            right: 0,
            bottom:0,
        },
        text: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "75%",
            flexShrink: 0,
        },
        disclaimer: {
            fontSize: 6,
            paddingBottom: 5,
            flexShrink: 0,
        },
        logo: {
            paddingLeft: 10,
            paddingRight: 10,
            width: "25%",
            flexShrink: 0,
            alignSelf: 'flex-end'
        }}
    );


    return <View fixed style={styles.footer}>
        <View style={styles.text}>
            <Text style={styles.disclaimer}>
                OMBA Advisory & Investments Limited is incorporated in England and Wales, Company Number 10594806. OMBA Advisory & Investments Limited is authorised and regulated by the Financial Conduct Authority 775647. OMBA Advisory & Investments Ltd is an authorised financial services provider (FSP No. 49101) in South Africa.
            </Text>
            <Text style={styles.disclaimer}>
                www.ombainvestments.com | www.ombafunds.com
            </Text>
        </View>
        <Image style={styles.logo} src={ombaLogo}/>
    </View>
}

export default Footer;