import {Data, Layout, LayoutAxis, PlotType} from "plotly.js";
import BranchValues from "./types";
import ChartData from "../interfaces/chartData";

export default function ombaGroupedBar(
    data: Data[],
    layout: Partial<Layout>,
    xaxisLayout: Partial<LayoutAxis>,
    aspectRatio: number = 1,
    height: number = 1000
): ChartData{

    return {
        data: data,
        layout: {
            ...layout,
            barmode: 'group',
            plot_bgcolor: "white",
            yaxis: {
                ticksuffix: '%',
                zeroline: true,
                zerolinecolor: "black",
                showline: true,
                linewidth: 0.5,
                linecolor: "black",
                ticks: "outside",
                automargin: true
            },
            xaxis: {
                showline: true,
                linewidth: 0.5,
                linecolor: "black",
                ticks: "outside",
                automargin: true,
                ...xaxisLayout
            },
            margin: {
                l: 0,
                t: 0,
                r: 0,
                b: 0
            }
        },
        image: {format: "png", width: height * aspectRatio, height: height, scale: 2}
    };
}