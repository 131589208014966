import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import EtfPositionsStore from "./etfPositionsStore";
import EtfPosition from "../etfPosition";
import OmbaEtfPosition from "../../../interfaces/ombaApi/ombaEtfPosition";

/*
The OMBA ETF Position Store which stores the Instruments in the OMBA ETF Positions Store via the OMBA APIs
 */
class OmbaEtfPositionsStore implements EtfPositionsStore {

    baseApiUrl: string;

    account?: AccountInfo;

    instance?: IPublicClientApplication;

    private accessToken?: string;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
    }

    setAccessToken(accessToken: string): void{
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }


    getEtfPositions(
        representativeDate: string,
        identifierType: string,
        identifierValues: string[],
        maxDepth?: string
    ): Promise<EtfPosition[]>{
        return this.getAccessToken().then((accessToken) => {

            let params = new URLSearchParams({
                representativeDate: representativeDate,
                identifierType: identifierType,
            });

            if (!(maxDepth == null)){
                params.append("lookThroughDepth", maxDepth)
            }

            return fetch(`${this.baseApiUrl}/positions/?` + params + "&identifierValue=" + identifierValues.join("&identifierValue="), {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => {
                    if (response.ok){
                        return response.json()
                    }
                    return response.text().then(text => {throw new Error(response.statusText + ": " + text)});
                })
                .then(res => {
                    return res as OmbaEtfPosition[]
                })
                .then(etfPositions => etfPositions.map(etfPosition => {
                    return new EtfPosition(
                        etfPosition.RepresentativeDate,
                        etfPosition.EtfIdentifiers,
                        etfPosition.UnderlyingInstrumentIdentifiers,
                        etfPosition.ProvidedETFId,
                        etfPosition.ETFId,
                        etfPosition.ETFIdentifierType,
                        etfPosition.ETFIdentifierValue,
                        etfPosition.ETFName,
                        etfPosition.ETFDescription,
                        etfPosition.ETFCurrency,
                        etfPosition.ETFType,
                        etfPosition.HoldingsDate,
                        etfPosition.UnderlyingInstrumentId,
                        etfPosition.UnderlyingInstrumentIdentifierType,
                        etfPosition.UnderlyingInstrumentIdentifierValue,
                        etfPosition.UnderlyingInstrumentName,
                        etfPosition.UnderlyingInstrumentDescription,
                        etfPosition.UnderlyingInstrumentCurrency,
                        etfPosition.UnderlyingInstrumentType,
                        etfPosition.UnderlyingInstrumentStatus,
                        etfPosition.Weight,
                        etfPosition.Quantity,
                        etfPosition.Lineage
                    )
                }));
        })
    }

    getOverlap(
        representativeDate: string,
        identifierType: string,
        identifierValues: string[]
    ): Promise<{[etfId: string]: {[etfId: string]: number}}>{
        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/positions/overlap/?` + new URLSearchParams({
                representativeDate: representativeDate,
                identifierType: identifierType
            }) + "&identifierValue=" + identifierValues.join("&identifierValue="), {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => response.json());
        })
    }
}

export default OmbaEtfPositionsStore