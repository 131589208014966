import ProfileStore from "../models/profile/stores/profileStore";
import Person from "../models/profile/person";


class ProfileViewModel {

    store: ProfileStore;

    constructor(profileStore: ProfileStore) {
        this.store = profileStore
    }

    getMyProfile(): Promise<Person>{
        return this.store.getMyProfile();
    }
}

export default ProfileViewModel