import React, { Fragment } from "react";
import Column from "../../interfaces/column";
import {StyleSheet, Text, View } from '@react-pdf/renderer';
import TableRowInfo from "../../interfaces/props/reports/tableRowInfo";
import { Style } from "@react-pdf/types";
import columnWidthCalculator from "./columnWidthCalculator";


function TableRow(props: TableRowInfo){

    const columns: Column[] = props.columns;
    const row: { [source: string] : {[attribute: string] : string}} = props.row;
    const rowStyles: Style = props.rowStyles;
    const cellStyles: Style = props.cellStyles;

    const getFontWeight = (column: Column) => {
        if (column.bold){
            return 'bold'
        }

        return 'normal'
    }

    const rowData = columns.map(column => {

        if (column.field in row){
            return <Text key={column.field} style={[cellStyles, {width: columnWidthCalculator(column, props.columns)}, {fontWeight: getFontWeight(column)}]}>
                    {row[column.field]}
                </Text>
        } else {
            return <Text key={column.field} style={[cellStyles, {width: columnWidthCalculator(column, props.columns)}]}>
                </Text>
        }
    });

    return <View wrap={false} style={[rowStyles]}>{rowData}</View>
}

export default TableRow