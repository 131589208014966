// Colour names form https://chir.ag/projects/name-that-color
const chartColours: {[hexColor: string]: string} = {
    '#1E3922': "Dark Green",
    '#801C1F': "Deep Red",
    '#9F7F4C': "Driftwood",
    '#3C3C3B': "Tuatara",
    '#264C55': "Blue Dianne",
    '#588951': "Hippie Green",
    '#CF523E': "Mojo",
    '#C59E08': "Buddha Gold",
    '#388CA1': "Astral",
    '#542D62': "Bossanova",
    '#525E48': "Axolotl",
    '#9C4A42': "Sepia Skin",
    '#B79F79': "Mongoose",
    '#6D6D6C': "Storm Dust",
    '#5D7980': "Cutty Sark",
    '#7E9D70': "Highland",
    '#DA765F': "Japonica",
    '#D4B646': "Old Gold",
    '#6AA9B9': "Tradewind",
    '#7F6289': "Rum",
    '#848A78': "Bitter",
    '#BA8275': "Old Rose",
    '#D0C0A6': "Vanilla",
    '#9D9D9D': "Silver Chalice",
    '#92A5AA': "Cascade",
    '#9FB390': "Schist",
    '#E39882': "Tonys Pink",
    '#E2CE83': "Putty",
    '#9BC5D0': "SinBad",
    '#A996B0': "Amethyst Smoke",
    '#BEBFB6': "Mist Gray",
    '#DABEB6': "Clam Shell",
    '#E7DFD3': "Pearl Bush",
    '#CECECE': "Alto",
    '#C8D2D4': "Tiara",
    '#C0CCB5': "Clay Ash",
    '#EDBBAA': "Desert Sand",
    '#F0E7C1': "Mint Julep",
    '#CDE2E7': "Botticelli",
    '#D4CAD8': "Gray Suit",
    '#E4E4E0': "Quill Gray",
    '#F0E4E1': "Dawn Pink",
    '#F5F2ED': "Spring Wood",
    '#EBEBEB': "Gallery",
    '#E9EDEE': "Porcelain",
    '#E0E5DA': "Green White",
    '#F6DED4': "Albescent White",
    '#F9F5E6': "Rum Swizzle",
    '#EBF3F5': "Catskill White",
    '#EEEAEF': "Athens Gray"
};

export default chartColours
