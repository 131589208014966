import {Data, Layout, PlotType} from "plotly.js";
import BranchValues from "./types";
import ChartData from "../interfaces/chartData";

export default function ombaMap(
    data: Data[],
    layout: Partial<Layout>,
    aspectRatio: number = 1
): ChartData{

    const height = 1000;

    return {
        data: data,
        layout: {
            ...layout,
            geo: {
                showframe: false,
                showcoastlines: true,
                showlinecolor: 'rgb(201, 209, 201)',
                countrycolor: 'rgb(201, 209, 201)',
                showland: true,
                showcountries: true,
                landcolor: 'rgb(255, 255, 255)',
                projection: {
                    type: 'natural earth'
                }
            }
        },
        image: {format: "png", width: height * aspectRatio, height: height, scale: 2}
    };
}